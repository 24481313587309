import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import Logo from "assets/images/AlianzaInvet.png";

const PDFRegistroHelmintos = ({ registro = {}, visitas = [], checkboxState = {} }) => {
  const doc = new jsPDF('p', 'mm', 'a4');
  const marginLeft = 12;
  const marginTop = 15;
  const marginBottom = 10;
  let yPosition = marginTop;

  const addText = (text, x, y, fontSize = 10, fontStyle = "normal") => {
    doc.setFontSize(fontSize);
    doc.setFont("times", fontStyle);
    doc.text(text, x, y);
  };

  const agregarNumeroPagina = () => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    doc.text(`Página ${pageCount}`, 180, 290); 
  };

  const addTextWithUnderline = (text, x, y, fontSize = 12, fontStyle = "normal") => {
    doc.setFontSize(fontSize);
    doc.setFont("times", fontStyle);
    doc.text(text, x, y);
    const textWidth = doc.getTextWidth(text);
    const lineHeight = 2; 
    doc.line(x, y + lineHeight, x + textWidth, y + lineHeight);
  };

  const drawPageBorder = () => {
    const pageWidth = 210; 
    const pageHeight = 297; 
    const margin = 10;
    doc.setLineWidth(0.5);
    doc.setDrawColor(0, 200, 0); 
    doc.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin);
  };

  doc.addImage(Logo, "PNG", 85, marginTop, 35, 25); 
  drawPageBorder();

  yPosition = marginTop + 35;
  addTextWithUnderline("PLANILLA DE REGISTRO DE RECUENTO PARASITARIO – HELMINTOS GASTROINTESTINALES", marginLeft, yPosition);
  yPosition += 10;

  autoTable(doc, {
    head: [
      [
        "Campo",
        "Información",
      ],
    ],
    body: [
      ["Código", "P-EFI-AC-004-V0.1"],
      ["Fecha de Vigencia", registro.fechaVigencia || ""],
      ["Próxima Revisión", registro.fechaRevision || ""],
      ["Versión", "0.1"],
      ["Profesional Actuante", registro.profesional || "--------------------------------------------------------"],
      ["Laboratorio", registro.laboratorio || ""],
      [
        "CAN", 
        checkboxState.can ? "✔" : "",
        "FEL", 
        checkboxState.fel ? "✔" : "",],
      [  "Prueba", 
        checkboxState.prueba ? "✔" : "",
        "Referencia", 
        checkboxState.referencia ? "✔" : "",
      ],
    ],
    startY: yPosition,
    theme: "grid",
    styles: {
      fontSize: 9,
      fillColor: [240, 240, 240], 
    },
    alternateRowStyles: {
      fillColor: [255, 255, 255],
    },
    headStyles: {
      fillColor: [100, 100, 100],
      textColor: [255, 255, 255],
    },
  });

  yPosition = doc.lastAutoTable.finalY + 15;

  autoTable(doc, {
    head: [
      [
        "Nº", "Identificación Animal", "Especie Parásita", 
        "Fecha Basal", "Valor Basal", "Fecha Día 15", "Valor Día 15", 
        "Fecha Día 30", "Valor Día 30"
      ]
    ],
    body: visitas.map((visita, index) => [
      index + 1,
      visita.identificacionAnimal || "",
      visita.especieParasita || "",
      visita.valoresHPG?.basal?.fecha || "",
      visita.valoresHPG?.basal?.valor || "",
      visita.valoresHPG?.dia15?.fecha || "",
      visita.valoresHPG?.dia15?.valor || "",
      visita.valoresHPG?.dia30?.fecha || "",
      visita.valoresHPG?.dia30?.valor || "",
    ]),
    startY: yPosition,
    theme: "striped",
    headStyles: { fillColor: [100, 100, 100], textColor: [255, 255, 255] },
    bodyStyles: { fontSize: 9, cellPadding: 4 },
    alternateRowStyles: { fillColor: [240, 240, 240] },
  });

  yPosition = doc.lastAutoTable.finalY + 15;

  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;
  
  const yPositionPie = pageHeight - marginBottom + 4;
  // Configuración del pie de página
  doc.setFontSize(8);
  doc.setFont("times", "normal");
  
  // Pie de página - parte izquierda
  doc.text("Powered by Kaizen Software", marginLeft, yPositionPie);
  
  // Pie de página - parte central
  doc.text("www.app-alianzainvet.com.ar", pageWidth / 2, yPositionPie, { align: "center" });
  agregarNumeroPagina(); // Add page number at the bottom

  return doc.output("dataurlnewwindow"); // Open PDF preview
};

export default PDFRegistroHelmintos;
