/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";

const tipoResponsables = [
  { id: 1, label: "IVA Responsable Inscripto" },
  { id: 2, label: "IVA Responsable no Inscripto" },
  { id: 3, label: "IVA no Responsable" },
  { id: 4, label: "IVA Sujeto Exento" },
  { id: 5, label: "Consumidor Final" },
  { id: 6, label: "Responsable Monotributo" },
  { id: 7, label: "Sujeto no Categorizado" },
  { id: 8, label: "Proveedor del Exterior" },
  { id: 9, label: "Cliente del Exterior" },
  { id: 10, label: "IVA Liberado – Ley Nº 19.640" },
  { id: 11, label: "IVA Responsable Inscripto – Agente de Percepción" },
  { id: 12, label: "Pequeño Contribuyente Eventual" },
  { id: 13, label: "Monotributista Social" },
  { id: 14, label: "Pequeño Contribuyente Eventual Social" },
];

const tipoDocumentos = [
  { id: 80, label: "CUIT" },
  { id: 86, label: "CUIL" },
  { id: 96, label: "DNI" },
]

const getRows = (data, handleOpenMenu) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip
                // title={item?.documentosPendientes > 0 ? "Hay documentos pendientes" : ""}
                title={""}
                placement="top"
              >
                 <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    // color: item?.documentosPendientes > 0 ? getUproColor("sistemasAmarillo") : getUproColor("sistemasGris"),
                    color: getUproColor("sistemasGris"),
                  }}
                  fontSize="lg"
                >
                  {/* {item?.documentosPendientes > 0 ? "new_releases" : "hail"} */}
                  hail
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        nombre: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.razonSocial || item?.nombre + " " + item?.apellido}
            </SoftTypography>
          </SoftBox>
        ),
        MP: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.MP}
            </SoftTypography>
          </SoftBox>
        ),
        documento: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {tipoDocumentos.find((tipo) => tipo.id === item.tipoDocumento)?.label + " " + item.documento}
            </SoftTypography>
          </SoftBox>
        ),
        tipoResponsable: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {tipoResponsables.find((tipo) => tipo.id === item.tipoResponsable)?.label}
            </SoftTypography>
          </SoftBox>
        ),
        provincia: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item.localidad?.provincia?.nombre || "Sin especificar"}
            </SoftTypography>
          </SoftBox>
        ),
        localidad: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item.localidad?.nombre || "Sin especificar"}
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <Tooltip title="Opciones" placement="top">
              <Icon
                sx={{ color: getUproColor("sistemasGris"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={(e) => handleOpenMenu(e, item)}
              >
                more_vert
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "MP", desc: "MP", width: "2%", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "documento", desc: "Documento", align: "left" },
  { name: "tipoResponsable", desc: "Tipo", align: "left" },
  { name: "provincia", desc: "Provincia", align: "left", orderField: "idProvincia" },
  { name: "localidad", desc: "Localidad", align: "left", orderField: "idLocalidad" },
  { name: "accion", desc: " ", align: "center", width: "1%" },
];

export default { columns, getRows };
