// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  ImageList,
  ImageListItem,
  Input,
  Modal,
  Tooltip,
} from "@mui/material";

import { gql, useMutation } from "@apollo/client";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { API_URL } from "services/config";
import SinDatos from "components/Carteles/SinDatos";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "95%",
  width: { xs: "80%", sm: "50%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};
const allowedFileTypes = ".jpg, .jpeg, .png, .gif";
const SAVE_FILES_PRODUCTO = gql`
  mutation addFrcFormFile($input: FrcFormFileInput!) {
    addFrcFormFile(input: $input) {
      id
      file
    }
  }
`;

const DELETE_FILE = gql`
  mutation deleteFrcFormFile($id: ID!) {
    deleteFrcFormFile(id: $id)
  }
`;


export default function ModalFotos({ open, handleClose, refetch, selectedProducto }) {
  const { handleSnackbar } = useContext(MessageManager);
  const [imagenes, setImagenes] = useState([]);
  const [imagenesData, setImagenesData] = useState([]);
  const [files, setFiles] = useState(null);
  const [deletedFiles, setDeletedFiles] = useState([]);

  const [mutate, { loading: loadingMutation }] = useMutation(SAVE_FILES_PRODUCTO, {
    onCompleted: () => {
      handleSnackbar("Cambios guardados", "success");
    },
    onError: () => {
      handleSnackbar("Ha ocurrido un error, intente más tarde", "error");
    },
  });
  const [mutateDelete, { loading: loadingDelete }] = useMutation(DELETE_FILE, {
    onCompleted: () => {},
    onError: () => {},
  });

  const handleFiles = (e) => {
    const filesArray = Array.from(e.target.files); 
    setFiles(filesArray); 
    const urls = filesArray.map((file) => URL.createObjectURL(file));
    setImagenes([...imagenes, ...urls]); 
  };


  const handleSave = async () => {
    if (deletedFiles.length > 0) {
      deletedFiles.forEach(async (file) => {
        await mutateDelete({
          variables: {
            id: file,
          },
        });
      });
    }
    if (files && files.length == 0) {
      handleSnackbar("Cambios guardados", "success");
      if (deletedFiles.length > 0) {
        refetch();
      }
      handleClose();
      return;
    }
    mutate({
      variables: {
        input: {
          file: files,
          idProducto: selectedProducto?.id,
        },
      },
    }).then(() => {
      setFiles(null);
      refetch();
      handleClose();
    });
  };

  
  useEffect(() => {
    if (open && selectedProducto) {
      setImagenesData(selectedProducto?.files || []);
    } else {
      setImagenes([]);
      setImagenesData([]);
      setDeletedFiles([]);
    }
  }, [open, selectedProducto]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={12} display="flex" flexDirection="row" justifyContent="space-between">
              <Grid></Grid>
              <SoftTypography variant="h6" align="center">
                Fotos de {selectedProducto?.nombre}
              </SoftTypography>
              <Grid>
                <Tooltip title="Cerrar" placement="top">
                  <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                    close
                  </Icon>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SoftButton
                color="primary"
                fullWidth
                onClick={() => {
                  document.getElementById("input-file").click();
                }}
              >
                Agregar <Icon>add_a_photo</Icon>
              </SoftButton>
            </Grid>
            <Grid item xs={12}>
              <ImageList sx={{ height: 450 }} cols={3} rowHeight={164}>
                {imagenes.length > 0 || imagenesData.length > 0 ? (
                  <>
                    {imagenes?.map((img, index) => (
                      <ImageListItem
                        key={index}
                        sx={{
                          position: "relative",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={`${img}`}
                          alt={selectedProducto?.nombre + "foto-" + index}
                          loading="lazy"
                          style={{ objectFit: "contain", width: "100%", height: "100%" }}
                        />
                        <Tooltip title="Eliminar" placement="top">
                          <Icon
                            sx={{
                              position: "absolute",
                              top: 5,
                              right: 5,
                              cursor: "pointer",
                              color: "white",
                              backgroundColor: "rgba(255,255,255,0.5)",
                              borderRadius: "50%",
                            }}
                            onClick={() => {
                              const newImagenes = imagenes.filter((imagen, i) => i !== index);
                              setImagenes(newImagenes);
                            }}
                          >
                            cancel
                          </Icon>
                        </Tooltip>
                      </ImageListItem>
                    ))}
                    {imagenesData?.map((img, index) => (
                      <ImageListItem
                        key={index}
                        sx={{
                          position: "relative",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          // src={`${API_URL}${img}`}
                          src={`${API_URL}/${img.file}`}
                          alt={selectedProducto?.nombre + "foto-" + index}
                          loading="lazy"
                          style={{ objectFit: "contain", width: "100%", height: "100%" }}
                        />
                        <Tooltip title="Eliminar" placement="top">
                          <Icon
                            sx={{
                              position: "absolute",
                              top: 5,
                              right: 5,
                              cursor: "pointer",
                              color: "white",
                              backgroundColor: "rgba(255,255,255,0.5)",
                              borderRadius: "50%",
                            }}
                            onClick={() => {
                              const newImagenes = imagenesData.filter((imagen, i) => i !== index);
                              setDeletedFiles([...deletedFiles, img.id]);
                              setImagenesData(newImagenes);
                            }}
                          >
                            cancel
                          </Icon>
                        </Tooltip>
                      </ImageListItem>
                    ))}
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    ></Grid>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                      <SinDatos
                        titulo="Sin fotos"
                        mensaje="No se han encontrado fotos para este producto"
                        icono="photo"
                      />
                    </Grid>
                  </>
                )}
              </ImageList>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="flex-end"
              sx={{
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Grid
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={loadingMutation || loadingDelete}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </Grid>
              <Grid
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  onClick={handleSave}
                  disabled={loadingMutation || loadingDelete}
                >
                  {false ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </Grid>
            </Grid>
            <Grid sx={{ display: "none" }}>
              <Input
                type="file"
                id="input-file"
                onChange={handleFiles}
                name="fotos"
                inputProps={{ accept: allowedFileTypes, multiple: true }}
              />
            </Grid>
          </Grid>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalFotos.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  selectedProducto: PropTypes.object,
};
