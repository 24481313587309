import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Grid, Icon, InputLabel, MenuItem, Select, ImageList, ImageListItem } from "@mui/material";
import { gql, useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { API_URL } from "services/config";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import ModalAddDeposito from "layouts/productos/components/ModalSucursales/ModalAddDeposito";
import ModalNewRaza from "./ModalRaza";
import ModalFotos from "./ModalFotos";

// Consultas y mutaciones
const GET_ESPECIES = gql`
  query GetEspecies {
    especies {
      id
      nombre
      tipo
    }
  }
`;

const GET_RAZAS = gql`
  query GetRazas {
    razas {
      id
      nombre
      idEspecie
    }
  }
`;

const CREATE_RAZA = gql`
  mutation createRaza($input: RazaInput!) {
    createRaza(input: $input) {
      id
      nombre
      idEspecie
    }
  }
`;

const DELETE_RAZA = gql`
  mutation deleteRaza($id: ID!) {
    deleteRaza(id: $id) {
      id
    }
  }
`;

const GET_FOTOS = gql`
  query GetFotos($id: ID!) {
    getFrcForm(id: $id) {
    documentos {
      id
      file
    }
  }
}
`;
const DELETE_FOTO = gql`
  mutation DeleteFoto($id: ID!) {
    deleteFrcFormFile(id: $id) 
  }
`;

const ProyectoDetalles = ({ proyecto = {}, setProyecto, large, MP }) => {
  const [modalSucursal, setModalSucursal] = useState(false);
  const [modalRaza, setModalRaza] = useState(false);
  const [createRaza] = useMutation(CREATE_RAZA);
  const [deleteRaza] = useMutation(DELETE_RAZA);
  const { data: razasData, refetch: refetchRazas } = useQuery(GET_RAZAS);  
  const { data: especiesData } = useQuery(GET_ESPECIES);
  const { data: fotosData, refetch: refetchFotos } = useQuery(GET_FOTOS, {
    variables: { id: proyecto.id },
  }, { fetchPolicy: 'no-cache'} );
  

  useEffect(() => {
    if (proyecto.id) {
      refetchFotos();
    }
  }, [proyecto.id]);
  const [deleteFoto] = useMutation(DELETE_FOTO);

const handleDeleteFoto = async (fotoId) => {
  try {
    await deleteFoto({ variables: { id: fotoId } });
    refetchFotos();
  } catch (error) {
  }
};
  const [modalFotosOpen, setModalFotosOpen] = useState(false);

  const handleOpenModalFotos = () => {
    setModalFotosOpen(true);
  };

  const handleCloseModalFotos = () => {
    setModalFotosOpen(false);
  };
  const [filteredRazas, setFilteredRazas] = useState([]);

  const especies = especiesData?.especies || [];
  const razas = razasData?.razas || [];

  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);
  
  useEffect(() => {
    fetch(`${API_URL}/get-fotos`)
      .then(response => response.json())
      .then(data => setFotosData(data))
      .catch((error) => {        
      });
  }, []); // El arreglo vacío significa que esto solo se ejecuta al cargar el componente


  useEffect(() => {
    if (proyecto?.provincia) {
      getLocalidades({ variables: { provincia: proyecto.provincia } }).then((data) => {
        if (!data?.data?.localidades?.find((localidad) => localidad.id === proyecto.localidad)) {
          setProyecto((prev) => ({
            ...prev,
            localidad: -1,
          }));
        }
      });
    }
  }, [proyecto?.provincia, getLocalidades, setProyecto]);

  useEffect(() => {
    if (proyecto?.idEspecie) {
      const nuevasRazas = razas.filter((raza) => raza.idEspecie === proyecto.idEspecie);
      setFilteredRazas(nuevasRazas);
    } else {
      setFilteredRazas([]);
    }
  }, [proyecto?.idEspecie, razas]);  

  const handleRazaChange = (value) => {
    if (value === "Agregar nueva raza") {
      handleOpenModalRaza();
    } else {
      setProyecto((prev) => ({ ...prev, idRaza: value }));
    }
  };

  const handleOpenModalRaza = () => {
    setModalRaza(true);
  };

  const handleCloseModalRaza = () => {
    setModalRaza(false);
  };

  const handleEspecieChange = (value) => {
    setProyecto((prev) => ({ ...prev, idEspecie: value }));
  };

  const handleDeleteRaza = async (id) => {
    try {
      await deleteRaza({ variables: { id } });  
      setFilteredRazas((prev) => prev.filter((raza) => raza.id !== id));
    } catch (error) {
      
    }
  };

  const handleCreateRaza = async (nombreRaza) => {
    try {
      const { data } = await createRaza({
        variables: { input: { nombre: nombreRaza, idEspecie: proyecto?.idEspecie } },
      });
      const nuevaRaza = data.createRaza;
      setFilteredRazas((prev) => [...prev, nuevaRaza]);
      refetchRazas();
    } catch (error) {
      
    }
  };
  
  return (
    <SoftBox>
      {large && (
        <SoftBox display="flex" justifyContent="space-between" mb={2}>
          <SoftTypography variant="h6">Formulario Parasitos</SoftTypography>
        </SoftBox>
      )}
      <SoftTypography variant="h6" fontWeight="bold">Datos Generales</SoftTypography>
      <Grid container spacing={2}>
        {/* Fotos */}	
        <Grid item xs={12} sm={12}>
          <SoftTypography variant="caption" fontWeight="bold">
            <SoftButton onClick={handleOpenModalFotos} color="primary" sx={{ width: { xs: '100%', sm: '250px' } }}>
              <Icon sx= {{ mr: 1 }}>add_a_photo</Icon>  Agregar Fotos
            </SoftButton>
          </SoftTypography>
        </Grid>
        {/* Profesional Actuante */}
        <Grid item xs={12} sm={5}>
          <InputLabel htmlFor="profesional">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Profesional Actuante *
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="Profesional Actuante"
            placeholder="Ingrese nombre del profesional"
            value={proyecto?.profesional ?? ''}
            onChange={(e) => setProyecto((prev) => ({ ...prev, profesional: e.target.value }))}
            required
          />
        </Grid>

        <Grid item xs={4} sm={2}>
          <InputLabel htmlFor="profesional">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              M.P. *
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="MP"
            placeholder="M.P."
            value={MP}
            onChange={(e) => {
              // Si solo deseas permitir números, puedes agregar esta validación
              const value = e.target.value;
              if (/^\d*$/.test(value)) {  // Filtra todo excepto números
                setProyecto((prev) => ({ ...prev, mp: value }));
              }
            }}
            required
          />
        </Grid>
        {/* Propietario */}
        <Grid item xs={8} sm={5}>
          <InputLabel htmlFor="tenedor">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Propietario *
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="Tenedor Responsable"
            placeholder="Ingrese nombre del tenedor responsable"
            value={proyecto?.propietario ?? ''}
            onChange={(e) => setProyecto((prev) => ({ ...prev, propietario: e.target.value }))}
          />
        </Grid>
        {/* Contacto */}
        <Grid item xs={12} sm={3}>
          <InputLabel htmlFor="contacto">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Contacto (Teléfono o correo) 
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="Contacto"
            placeholder="Ingrese contacto"
            value={proyecto?.contacto ?? ''}
            onChange={(e) => setProyecto((prev) => ({ ...prev, contacto: e.target.value }))}
          />
        </Grid>

        {/* Provincia */}
        <Grid item xs={12} sm={4.5}>
          <InputLabel htmlFor="provincia">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Provincia *
            </SoftTypography>
          </InputLabel>
          <Select
            name="provincia"
            value={proyecto?.provincia || -1}
            onChange={(e) => setProyecto((prev) => ({ ...prev, provincia: e.target.value }))}
          >
            <MenuItem value={-1} disabled>
              Seleccione uno
            </MenuItem>
            {provincias?.provincias?.map((provincia) => (
              <MenuItem key={provincia.id} value={provincia.id}>
                {provincia.nombre}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/* Localidad */}
        <Grid item xs={12} sm={4.5}>
          <InputLabel htmlFor="localidad">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Localidad *
            </SoftTypography>
          </InputLabel>
          <Select
            name="localidad"
            value={proyecto?.localidad || -1}
            onChange={(e) => setProyecto((prev) => ({ ...prev, localidad: e.target.value }))}
          >
            <MenuItem value={-1} disabled>
              Seleccione uno
            </MenuItem>
            {localidades?.localidades?.map((localidad) => (
              <MenuItem key={localidad.id} value={localidad.id}>
                {localidad.nombre}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/* Nombre del Animal */}
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="nombre">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Nombre del Animal *
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="Nombre del Animal"
            placeholder="Ingrese nombre del animal"
            value={proyecto?.nombre ?? ''}
            onChange={(e) => setProyecto((prev) => ({ ...prev, nombre: e.target.value }))}
          />
        </Grid>

        {/* Pelaje */}
        <Grid item xs={12} sm={3}>
          <InputLabel htmlFor="pelaje">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Pelaje *
            </SoftTypography>
          </InputLabel>
          <Select
            value={proyecto?.pelaje || ""}
            onChange={(e) => setProyecto((prev) => ({ ...prev, pelaje: e.target.value }))}
            fullWidth
          >
            <MenuItem value="Corto">Corto</MenuItem>
            <MenuItem value="Medio">Medio</MenuItem>
            <MenuItem value="Largo">Largo</MenuItem>
          </Select>
        </Grid>
        {/* Edad */}
        <Grid item xs={12} sm={3}>
          <InputLabel htmlFor="edad">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Edad *
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="Edad"
            placeholder="Ingrese edad"
            value={proyecto?.edad ?? ''}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setProyecto((prev) => ({ ...prev, edad: value }));
              }
            }}
          />
        </Grid>
        {/* Sexo */}
        <Grid item xs={12} sm={4}>
          <InputLabel htmlFor="sexo">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Sexo *
            </SoftTypography>
          </InputLabel>
          <Select
            value={proyecto?.sexo || ""}
            onChange={(e) => setProyecto((prev) => ({ ...prev, sexo: e.target.value }))}
            fullWidth
          >
            <MenuItem value="Macho">Macho</MenuItem>
            <MenuItem value="Hembra">Hembra</MenuItem>
          </Select>
        </Grid>

        {/* Especie */}
        <Grid item xs={12} sm={4}>
          <InputLabel htmlFor="especie">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Especie *
            </SoftTypography>
          </InputLabel>
          <Select
            value={proyecto?.idEspecie || ""}
            onChange={(e) => handleEspecieChange(e.target.value)}
            fullWidth
          >
            {especies
              .filter((especie) => especie.nombre === "Canino" || especie.nombre === "Felino")
              .map((especie) => (
                <MenuItem key={especie.id} value={especie.id}>
                  {especie.nombre}
                </MenuItem>
              ))}
          </Select>
        </Grid>

        {/* Raza */}
        <Grid item xs={12} sm={4}>
          <InputLabel htmlFor="raza">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Raza *
            </SoftTypography>
          </InputLabel>
          <Select
            value={proyecto?.idRaza || ""}
            onChange={(e) => handleRazaChange(e.target.value)}
            fullWidth
          >
            {filteredRazas.map((raza) => (
              <MenuItem key={raza.id} value={raza.id}>
                <SoftBox display="flex" justifyContent="space-between" width="100%">
                  <span>{raza.nombre}</span>
                  <Icon onClick={(e) => { e.stopPropagation(); handleDeleteRaza(raza.id); }}>delete</Icon>
                </SoftBox>
              </MenuItem>
            ))}
            <MenuItem value="Agregar nueva raza">Agregar nueva raza</MenuItem>
          </Select>

        </Grid>

        {/* Observaciones */}
        <Grid item xs={12}>
          <InputLabel htmlFor="observaciones">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Observaciones
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="Observaciones"
            placeholder="Ingrese observaciones"
            value={proyecto?.observaciones ?? ''}
            onChange={(e) => setProyecto((prev) => ({ ...prev, observaciones: e.target.value }))}
          />
        </Grid>
        {/* Ver Fotos */}
        <Grid item xs={12}>
          <SoftTypography variant="h6">Fotos del Formulario</SoftTypography>
          {fotosData ? (
            <ImageList sx={{ height: 450 }} cols={3} rowHeight={164}>
              {(fotosData?.getFrcForm?.documentos || []).map((foto, index) => (
                <ImageListItem key={index}>
                  <img
                    src={`${API_URL}/${foto.file}`}
                    alt={`foto-${index}`}
                    loading="lazy"
                    style={{ objectFit: "contain", width: "100%", height: "100%" }}
                  />
                  <SoftButton
                    color="error"
                    sx={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                    }}
                    onClick={() => handleDeleteFoto(foto.id)} // Elimina la foto cuando el usuario haga clic
                  >
                    <Icon sx={{ fontSize: 20 }}>delete</Icon>
                  </SoftButton>
                </ImageListItem>
              ))}
            </ImageList>
          ) : (
            <SoftTypography variant="caption">Cargando fotos...</SoftTypography>
          )}
        </Grid>
      </Grid>

      <ModalAddDeposito open={modalSucursal} handleClose={() => setModalSucursal(false)} />
      <ModalNewRaza
        open={modalRaza}
        handleClose={handleCloseModalRaza}
        handleCloseCancel={handleCloseModalRaza}
        especieId={proyecto?.idEspecie}
        handleCreateRaza={handleCreateRaza}
        refetchRazas={refetchRazas}
      />
      <ModalFotos
        open={modalFotosOpen}
        handleClose={handleCloseModalFotos}
        refetch={refetchFotos} // Si necesitas refrescar los datos después de guardar
        selectedProducto={proyecto} // Aquí pasas los datos del producto actual
      />

    </SoftBox>
  );
};

ProyectoDetalles.propTypes = {
  proyecto: PropTypes.object,
  setProyecto: PropTypes.func.isRequired,
  large: PropTypes.bool,
  user: PropTypes.object.isRequired,
  MP: PropTypes.number.isRequired,
};

export default ProyectoDetalles;
