// React

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import { Card, Fade, Icon, Menu, MenuItem, Modal, Tooltip } from "@mui/material";

import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import ModalDelete from "components/Modals/Delete";
import CustomPagination from "components/Pagination";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import { MessageManager } from "context";
import proveedoresData from "layouts/productos/data/proveedoresData";

import { getUproColor } from "utils/colors";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import ModalAddProveedor from "./components/ModalAdd";
// import ModalDocumentacionProveedor from "./components/Documentacion";
import ModalDocumentacion from "./components/ModalDocumentacion";
import { UserContext } from "context/user";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
};

const GET_PROVEEDORES = gql`
  query getProveedores($filter: JSON, $search: String, $page: Int, $order: Order) {
    paginationInfo {
      pages
      total
    }
    proveedores(
      filter: $filter
      search: $search
      pagination: { page: $page, limit: 10 }
      order: $order
    ) {
      id
      tipoProveedor
      MP
      razonSocial
      nombre
      apellido
      tipoDocumento
      documento
      tipoResponsable
      
       localidad {
         id
         nombre
         provincia {
           id
           nombre
         }
      }
      
    }
  }
`;

const DELETE_PROVEEDOR = gql`
  mutation deleteProveedor($id: ID!) {
    deleteProveedor(id: $id) {
      id
    }
  }
`;

function Proveedores() {
  const { verificarAcceso } = useContext(UserContext);
  const [proveedor, setProveedor] = useState();
  const [rows, setRows] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const { columns, getRows } = proveedoresData;
  const { handleSnackbar } = useContext(MessageManager);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "nombre",
    orden: "DESC",
  });
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDocumentacion, setOpenModalDocumentacion] = useState(false);

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [getProveedores, { loading, data: proveedores, refetch }] = useLazyQuery(GET_PROVEEDORES, {
    variables: {
      search: search || null,
      page: page,
      order: { field: orderBy.campo, order: orderBy.orden },
    },
    fetchPolicy: "network-only",
    cachePolicy: "no-cache",
  });
  const [deleteProveedor] = useMutation(DELETE_PROVEEDOR);

  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);

  const handleDelete = (id) => {
    deleteProveedor({
      variables: {
        id,
      },
    })
      .then(() => {
        getProveedores();
        handleSnackbar("Proveedor eliminado correctamente", "success");
      })
      .catch(() => {
        handleSnackbar("Error al eliminar el proveedor", "error");
      });
  };

  const handleOpenMenu = (event, item) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
    setSelectedItem();
  };

  const renderMenu = (
    <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(openMenu)} onClose={handleCloseMenu}>
      {
        verificarAcceso(42) && (
          <MenuItem
            onClick={() => {
              setOpenModalEdit(true);
              setProveedor({
                tipoProveedor: selectedItem.tipoProveedor,
                MP: selectedItem.MP,
                nombre: selectedItem.nombre,
                apellido: selectedItem.apellido,
                tipoDocumento: selectedItem.tipoDocumento,
                documento: selectedItem.documento,
                tipoResponsable: selectedItem.tipoResponsable,
                razonSocial: selectedItem.razonSocial,
                provincia: selectedItem.localidad?.provincia?.id,
                localidad: selectedItem.localidad?.id,
                id: selectedItem.id,
              });
              handleCloseMenu();
            }}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <SoftBox>
                <SoftTypography variant="p">Editar</SoftTypography>
              </SoftBox>
              <SoftBox>
                <Icon
                  fontSize="small"
                  sx={{
                    verticalAlign: "middle",
                    color: getUproColor("sistemasAmarillo"),
                  }}
                >
                  edit
                </Icon>
              </SoftBox>
            </SoftBox>
          </MenuItem>
        )
      }
      {
        verificarAcceso([44,45], "or") && (
          <MenuItem
            onClick={() => {
              setOpenModalDocumentacion(true);
              setAnchorEl(null);
              setOpenMenu(false);
            }}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <SoftBox>
                <SoftTypography variant="p">Documentación</SoftTypography>
              </SoftBox>
              <SoftBox>
                <Icon
                  fontSize="small"
                  sx={{
                    verticalAlign: "middle",
                    color: getUproColor("sistemasGris"),
                  }}
                >
                  description
                </Icon>
              </SoftBox>
            </SoftBox>
          </MenuItem>
        )
      }
      {
        verificarAcceso(43) && (
          <MenuItem
            onClick={() => {
              setOpenModalDelete(true);
              setAnchorEl(null);
              setOpenMenu(false);
            }}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <SoftBox>
                <SoftTypography variant="p">Eliminar</SoftTypography>
              </SoftBox>
              <SoftBox>
                <Icon
                  fontSize="small"
                  sx={{
                    verticalAlign: "middle",
                    color: getUproColor("sistemasNegro"),
                  }}
                >
                  delete
                </Icon>
              </SoftBox>
            </SoftBox>
          </MenuItem>
        )
      }
    </Menu>
  );

  useEffect(() => {
    getProveedores();
    setProveedor();
    refetch();
  }, []);

  useEffect(() => {
    if (proveedores && proveedores?.proveedores) {
      setRows(getRows(proveedores.proveedores, handleOpenMenu));
      setPagesInfo(proveedores?.paginationInfo);
    }
  }, [proveedores]);

  useEffect(() => {
    if (proveedor?.provincia) {
      getLocalidades({ variables: { provincia: proveedor?.provincia } }).then((data) => {
        // Si la localidad no pertenece a la provincia seleccionada, se limpia el campo
        if (!data?.data?.localidades?.find((localidad) => localidad.id === proveedor?.localidad)) {
          setProveedor({
            ...proveedor,
            localidad: -1,
          });
        }
      });
    }
  }, [proveedor?.provincia]);

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card >
            <SoftBox>
              <SoftBox display="flex" justifyContent="space-between" p={3}>
                <SoftBox>
                  <SoftTypography variant="h6">Proveedores</SoftTypography>
                </SoftBox>
                <SoftBox display="flex" justifyContent="flex-end">
                  <SoftBox mr={2}>
                    <SoftInput
                      placeholder="Escribe aquí..."
                      icon={{ component: "search", direction: "left" }}
                      onChange={handleSearch}
                    />
                  </SoftBox>
                  {
                    verificarAcceso(42) && (    
                      <SoftBox mr={2}>
                        <Tooltip title="Agregar nuevo proveedor" placement="top">
                          <SoftButton color="primary" circular onClick={() => setOpenModalEdit(true)}>
                            <Icon>add</Icon>
                            &nbsp;Agregar
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    )
                  }
                </SoftBox>
              </SoftBox>
              <SoftBox
                sx={{
                  maxHeight: "50vh",
                  overflow: "auto",
                }}
              >
                {loading ? (
                  <Loading />
                ) : proveedores?.proveedores?.length > 0 ? (
                  <DataTable columns={columns} rows={rows} order={orderBy} setOrder={handleOrderBy} />
                ) : (
                  <SinDatos />
                )}
              </SoftBox>
              <CustomPagination
                loading={loading}
                length={proveedores?.proveedores?.length}
                total={pagesInfo?.total}
                pages={pagesInfo?.pages}
                page={page}
                setPage={setPage}
                noCard
              />
              {renderMenu}
            </SoftBox>
            <ModalAddProveedor
              open={openModalEdit}
              handleClose={() => {
                setOpenModalEdit(false);
                setProveedor();
              }}
              data={proveedor}
              refetch={refetch}
            />
            <ModalDelete
              open={openModalDelete}
              handleClose={() => setOpenModalDelete(false)}
              nombre={`el proveedor ${selectedItem?.razonSocial || selectedItem?.nombre+" "+selectedItem?.apellido}`}
              functionDelete={() => {
                handleDelete(selectedItem?.id);
              }}
            />
           
            <ModalDocumentacion
            open={openModalDocumentacion}
            handleClose={() =>{
              setOpenModalDocumentacion(false);
              setSelectedItem();
            }}
            idProveedor={selectedItem?.id}
            readOnly={false}
            />
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer/>
    </DashboardLayout>
  );
}

export default Proveedores;
