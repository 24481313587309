import { useMemo, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Icon, Table as MuiTable, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ViewListIcon from '@mui/icons-material/ViewList';
import CheckIcon from '@mui/icons-material/Check';
import DescriptionIcon from '@mui/icons-material/Description';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ModalEditProyecto from "layouts/proyectos/ModalEdit";  

const DELETE_PROYECTO = gql`
  mutation deleteProyecto($id: ID!) {
    deleteProyecto(id: $id) {
      id
    }
  }
`;

function DataTableProyect({ columns, rows, refetch, user }) {
  const navigate = useNavigate();
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const [deleteProyecto] = useMutation(DELETE_PROYECTO, {
    onCompleted: () => {
      if (refetch) {
        refetch(); // Actualiza la lista de proyectos al eliminar uno
      }
    },
    onError: (error) => {
      console.error("Error al eliminar proyecto:", error);
    },
  });

  const handleEditClick = (row) => {
    setSelectedRow(row);  // Establece la fila seleccionada
    setModalEditOpen(true);  // Abre el modal
  };
  
  const handleCloseModal = () => {
    setModalEditOpen(false);
    setSelectedRow(null);  // Restablece la fila seleccionada
    if (refetch) {
      refetch(); // Recarga los proyectos después de editar
    }
  };

  const handleDeleteClick = (row) => {
    setRowToDelete(row); // Establece el proyecto que se va a eliminar
    setConfirmationDialogOpen(true); // Abre el cuadro de confirmación
  };

  const confirmDelete = () => {
    if (rowToDelete) {
      deleteProyecto({ variables: { id: rowToDelete.id } });
    }
    setConfirmationDialogOpen(false); // Cierra el cuadro de confirmación
    setRowToDelete(null); // Restablece el proyecto seleccionado para eliminar
  };

  const cancelDelete = () => {
    setConfirmationDialogOpen(false); // Cierra el cuadro de confirmación sin eliminar
    setRowToDelete(null); // Restablece el proyecto seleccionado
  };

  const userPermissions = user?.permisos || [];
  const canEdit = userPermissions.includes(50) || user.tipoUser === 'Administrador'; 
  const canDelete = userPermissions.includes(51) || user.tipoUser === 'Administrador'; 
  const canContrat = userPermissions.includes(53) || user.tipoUser === 'Administrador';
  const renderColumns = columns.map(({ name, desc, align, width }) => (
    <TableCell
      key={name}
      width={width || "auto"}
      align={align}
      pt={1.5}
      pb={1.25}
      sx={{
        fontSize: typography.size.xxs,
        fontWeight: typography.fontWeightBold,
        color: "secondary",
        opacity: 0.7,
        borderBottom: `${borders.borderWidth[1]} solid ${colors.light.main}`,
      }}
    >
      <SoftTypography variant="p" fontWeight="bold" color="dark">
        {desc ? desc.toUpperCase() : (name ? name.toUpperCase() : '')}
      </SoftTypography>
    </TableCell>
  ));

  const renderRows = rows.map((row) => (
    <TableRow key={row.id} hover>
      {columns.map(({ name, align, render }) => (
        <TableCell key={name} align={align}>
          <SoftTypography variant="button" color="secondary" fontSize={typography.size.xs}>
            {render ? render(row) : row[name] !== undefined ? row[name] : ""}
          </SoftTypography>
        </TableCell>
      ))}
      {/* Nueva columna para los iconos */}
      <TableCell align="center">
        
        <Tooltip title="Ver Lista de Formularios" arrow>
          <Icon
            onClick={() => navigate(`/proyectos/Formulario_Registro_Clinico/${row.id}`)}
            style={{ cursor: 'pointer', marginRight: '8px', color: '#66BB6A' }}
          >
            <ViewListIcon />
          </Icon>
        </Tooltip>
        <Tooltip title="Agregar Formulario RC" arrow>
          <Icon
            onClick={() => navigate(`/proyectos/form-frc/${row.id}`)}
            style={{ cursor: 'pointer', marginRight: '8px', color: 'orange' }}
          >
            <AddIcon />
          </Icon>
        </Tooltip>
        {/* <Tooltip title="Consentimiento" arrow>
          <Icon
            onClick={() => navigate()}
            style={{ cursor: 'pointer', marginRight: '8px', color: '#66BB6A' }}
          >
            <CheckIcon />
          </Icon>
        </Tooltip> */}
        <Tooltip title="Contrato" arrow>
          <Icon
            onClick={() => navigate(`/proyectos/contrato/${row.id}`)}
            style={{
              cursor: canContrat ? 'pointer' : 'not-allowed', 
              marginRight: '8px', 
              color: canContrat ? '#800000' : 'grey'
            }}
          >
            <DescriptionIcon />
          </Icon>
        </Tooltip>
        <Tooltip title="Editar" arrow>
          <Icon
            onClick={() => handleEditClick(row)}
            style={{ 
              cursor: canEdit ? 'pointer' : 'not-allowed', 
              marginRight: '8px', 
              color: canEdit ? 'blue' : 'grey' 
            }}
          >
            <EditIcon />
          </Icon>
        </Tooltip>
        <Tooltip title="Eliminar" arrow>
        <Icon
            onClick={() => handleDeleteClick(row)} // Llamada al método para mostrar el diálogo de confirmación
            style={{ cursor: canDelete ? 'pointer' : 'not-allowed', marginRight: '8px',
            color: canDelete ? 'red' : 'grey' }}
          >
            <DeleteIcon />
          </Icon>
        </Tooltip>
      </TableCell>
    </TableRow>
  ));

  return useMemo(
    () => (
      <>
        <TableContainer>
          <MuiTable>
            <SoftBox component="thead">
              <TableRow>{renderColumns}</TableRow>
            </SoftBox>
            <TableBody>{renderRows}</TableBody>
          </MuiTable>
        </TableContainer>

        {/* Modal para editar el proyecto */}
        {modalEditOpen && selectedRow && (
          <ModalEditProyecto
            open={modalEditOpen}
            onClose={handleCloseModal}
            onEdit={handleEditClick}
            proyectoId={selectedRow.id}
          />
        )}
        <Dialog
          open={confirmationDialogOpen}
          onClose={cancelDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirmar eliminación"}</DialogTitle>
          <SoftBox px={3}>
            <SoftTypography variant="body1" color="secondary">
              ¿Estás seguro de que deseas eliminar este proyecto? Esta acción no se puede deshacer.
            </SoftTypography>
          </SoftBox>
          <DialogActions>
            <SoftButton
              onClick={cancelDelete}
              style={{ cursor: 'pointer', backgroundColor: '#f44336', color: 'white' }}
            >
              Cancelar
            </SoftButton>
            <SoftButton
              onClick={confirmDelete}
              style={{ cursor: 'pointer', backgroundColor: '#66BB6A', color: 'white' }}
            >
              Confirmar
            </SoftButton>
          </DialogActions>
        </Dialog>
      </>
    ),
    [columns, rows, modalEditOpen, selectedRow, refetch, navigate, canEdit, canDelete, confirmationDialogOpen, rowToDelete]
  );
}

DataTableProyect.defaultProps = {
  columns: [],
  rows: [],
};

DataTableProyect.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    desc: PropTypes.string,
    align: PropTypes.string,
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  refetch: PropTypes.func.isRequired,
};

export default DataTableProyect;
