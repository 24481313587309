import React, { useEffect, useMemo } from "react";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import Logo from "assets/images/AlianzaInvet.png"; 
import { gql, useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";

// Query de GraphQL
const GET_FRC_FORM = gql`
  query GetFrcForm($id: ID!) {
    getFrcForm(id: $id) {
      id
      profesional
      propietario
      contacto
      idProvincia
      idLocalidad
      nombre
      pelaje
      idEspecie
      sexo
      idRaza
      edad
      observaciones

      ecto_nroPulgas
      ecto_nroGarrapatas
      ecto_intensidadPulgas
      ecto_intensidadGarrapatas

      endo_idEspecie
      endo_hpg
      endo_formulacionRecibida
      endo_fechaTratamiento
      endo_fechaTratamiento1
      endo_fechaTratamiento2
      endo_prueba
      endo_referencia
      endo_tipo
      endo_dosis

      eog_peso
      eog_mucosas
      eog_linfodulos
      eog_temperatura
      eog_sensorio
      eog_frecuenciaRespiratoria
      eog_frecuenciaCardiaca
      eog_hidratacion
      eog_consumo
      eog_celo
      eog_fechaInicio
      eog_fechaAlta
      eog_obs

      mr_medicacionRecibida
      mr_antecedentes
      mr_signoDermatitis

      rea_posibleRelacion
      rea_exclusion
      rea_trataConcomitante
      rea_altaClinica
      rea_observaciones

      proyectoId
      documentos {
        id
        nombre
        file
        createdAt
      }
      localidad {
        id
        nombre
      }
      eventosAdversos {
        id
        fecha
        descripcion
        severidad
        accionTomada
        notificador
        relacionTratamiento
      }
      convivientes {
        id
        nombre
        sexo
        edad
        raza
        peso
        volumen
        observaciones
        tipo
      }
    }
  }
`;

const intensidadPulgas = (intensidad) => {
  if (intensidad >= 20) return "Alta Intensidad";
  if (intensidad >= 6) return "Moderada Intensidad";
  return "Baja Intensidad";
};

const PdfFormulariosRC = ({ id }) => {
    const [loadFrcForm, { loading, data, error }] = useLazyQuery(GET_FRC_FORM, {
      variables: { id },
      fetchPolicy: 'network-only', // Asegura que siempre obtenemos los datos más recientes
    });

    useEffect(() => {
      if (id){
      loadFrcForm ();
      }
    }, [id, loadFrcForm]);
  
    // Función para generar el PDF
    
      const generatePdf = () => {

        if (!data) return; 
        const doc = new jsPDF();
        const marginLeft = 15;
        const marginTop = 15;
        const marginBottom = 10; 
        const logoHeight = 25; // Altura del logo
        const pageWidth = 210;
        const pageHeight = 297;
        let pageNumber = 0;
        let startY = marginTop + logoHeight + 10;
        
    
        // Función para agregar texto
        const addText = (text, x, y, fontSize = 10, fontStyle = "normal") => {
          doc.setFontSize(fontSize);
          doc.setFont("times", fontStyle);
          doc.text(text, x, y);
        };
    
        // Función para agregar números de página
        const addPageNumber = () => {
          const pageCount = doc.internal.getNumberOfPages();
          doc.setFontSize(9);
          doc.text(`Página ${pageCount}`, 180, 290);
        };
    
        // Función para dibujar el borde de la página
        const drawPageBorder = () => {
          doc.setLineWidth(0.5);
          doc.setDrawColor(0, 200, 0); // Color verde para el borde
          doc.rect(10, 10, pageWidth - 20, pageHeight - 20);
        };
    
        // Función para agregar el logo en la primera página
        const addLogo = () => {
          doc.addImage(Logo, "PNG", 85, marginTop, 35, logoHeight); // (imagen, formato, x, y, ancho, altura) 
        };
        const addTextWithUnderline = (text, x, y, fontSize = 12, fontStyle = "normal") => {
          doc.setFontSize(fontSize);
          doc.setFont("times", fontStyle);
          doc.text(text, x, y);
          // Subrayar
          const textWidth = doc.getTextWidth(text);
          const lineHeight = 2; 
          doc.line(x, y + lineHeight, x + textWidth, y + lineHeight);
        };
        // Función para agregar el contenido de la página
        const addContent = (startY) => {
          // Encabezado (ajustado para tener el mismo margen)
          addTextWithUnderline(`FORMULARIO DE REGISTRO CLÍNICO Nro: ${data.idFormulario || 'N/A'}`, marginLeft + 38, startY);
          doc.setFontSize(10);
          addText("Código: FRC-EFI-AC-001-V03", marginLeft, startY + 10);
          // addText("Fecha de vigencia: data", marginLeft, startY + 15);
          // addText("Próxima Revisión: 11/11/2022", marginLeft, startY + 20);
          addText(`ID Proyecto: ${data.proyectoId || 'N/A'}`, marginLeft, startY + 15);
    
          
        };
        // Agregar el logo en la primera página
        addLogo();
        drawPageBorder();
        addContent(marginTop + logoHeight + 10); 
        autoTable(doc, {
          startY: startY + 40,
          // head: [["Provincia", "Localidad"]],
          // body: [[data.idProvincia, data.idLocalidad]],
          theme: "grid",
          styles: { halign: "center" },
          headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
          bodyStyles: { fontSize: 9 },
          didDrawPage: function (data) {
            pageNumber++;
            addPageNumber();
            if (pageNumber > 1) {
              drawPageBorder(); 
            }
            const yPosition = pageHeight - marginBottom +4;
            doc.setFontSize(8);
            doc.setFont("times", "normal");
            // Pie de página - parte izquierda
            doc.text("Powered by Kaizen Software", marginLeft, yPosition);
            // Pie de página - parte central
            doc.text("www.app-alianzainvet.com.ar", pageWidth / 2, yPosition, { align: "center" });
          }
        });
    
        // Datos generales - tabla
        autoTable(doc, {
          startY: startY +25 ,
          head: [["Profesional Actuante","M.P.", "Tenedor Responsable", "Contacto (tel/mail)"]],
          body: [[data.profesional, data.propietario, data.contacto]],
          theme: "grid",
          styles: { halign: "center" },
          headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
          bodyStyles: { fontSize: 9 },
        });
        autoTable(doc, {
          startY: doc.lastAutoTable.finalY ,
          head: [["Provincia", "Localidad"]],
          body: [[data.idProvincia, data.idLocalidad]],
          theme: "grid",
          styles: { halign: "center" },
          headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
          bodyStyles: { fontSize: 9 },
        })
        // Información del Animal - tabla
        autoTable(doc, {
          startY: doc.lastAutoTable.finalY + 5,
          head: [["Nombre del Animal", "Especie", "Sexo", "Raza", "Edad"]],
          body: [[data.nombre, data.idEspecie, data.sexo, data.idRaza, data.edad]],
          theme: "grid",
          styles: { halign: "center" },
          headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
          bodyStyles: { fontSize: 9 },
        });
        // Ectoparásitos - tabla
        autoTable(doc, {
          startY: doc.lastAutoTable.finalY + 5,
          head: [["Ectoparásitos", "Cantidad", "Intensidad de Infestación"]],
          body: [
            ["Pulgas", data.ecto_nroPulgas, intensidadPulgas(data.ecto_intensidadPulgas)],
            ["Garrapatas", data.ecto_nroGarrapatas, data.ecto_intensidadGarrapatas],
          ],
          theme: "grid",
          headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
          bodyStyles: { fontSize: 9 },
        });
        // Endoparásitos - tabla
        autoTable(doc, {
          startY: doc.lastAutoTable.finalY + 5,
          head: [["Endoparásitos - Especies", "Valor de HPG", "Formulación Recibida", "Fecha Tratamiento"]],
          body: [
            [data.endo_idEspecie, data.endo_hpg, data.endo_formulacionRecibida, data.endo_fechaTratamiento],
          ],
          theme: "grid",
          headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
          bodyStyles: { fontSize: 9 },
        });
        // Examen Objetivo General - tabla
        autoTable(doc, {
          startY: doc.lastAutoTable.finalY + 10,
          head: [["Examen Objetivo General", "Valor", "Unidad"]],
          body: [
            ["Peso", data.eog_peso, "kg"],
            ["Mucosas", data.eog_mucosas, "tllc"],
            ["Linfonódulos", data.eog_linfodulos, "-"],
            ["Temperatura", data.eog_temperatura, "°C"],
            ["Sensorio", data.eog_sensorio, "-"],
            ["Frecuencia Respiratoria", data.eog_frecuenciaRespiratoria, "Resp./min"],
            ["Frecuencia Cardíaca", data.eog_frecuenciaCardiaca, "Lat/min"],
            ["Hidratación", data.eog_hidratacion, "Seg."],
            ["Consumo (agua/alimento)", data.eog_consumo, "-"],
            ["Celo/Gestación", data.eog_celo, "-"],
            ["Fecha Inicio", data.eog_fechaInicio, ""],
            ["Fecha Alta", data.eog_fechaAlta, ""],
          ],
          theme: "grid",
          headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
          bodyStyles: { fontSize: 9 },
        });
        // Observaciones - tabla
        autoTable(doc, {
          startY: doc.lastAutoTable.finalY + 10,
          head: [["Observaciones"]],
          body: [
            [data.eog_obs || ''],
          ],
          theme: "grid",
          headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
          bodyStyles: { fontSize: 9, cellPadding: 5 },
          columnStyles: {
            0: { cellWidth: 'auto', valign: 'top' } // Ajusta el tamaño de la celda
          },
        });
        // Medicaciones Recibidas - tabla
        autoTable(doc, {
          startY: doc.lastAutoTable.finalY + 5,
          head: [["Medicaciones Recibidas", "Hipersensibilidad", "Dermatitis"]],
          body: [
            [
              data.mr_medicacionRecibida || 'No aplicable',
              data.mr_antecedentes === true ? 'SI' : (data.mr_antecedentes === false ? 'NO' : 'No disponible'),
              data.mr_signoDermatitis || 'No disponible'
            ],
          ],
          theme: "grid",
          headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
          bodyStyles: { fontSize: 9 },
        });
    
        // Animales Convivientes - tabla
        autoTable(doc, {
          startY: doc.lastAutoTable.finalY + 5,
          head: [["Animales Convivientes"]],
          body: [
            [data.convivientes && data.convivientes?.length > 0 ? 'SI' : 'NO'],
          ],
          theme: "grid",
          headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
          bodyStyles: { fontSize: 9 }
        });
        autoTable(doc, {
          startY: doc.lastAutoTable.finalY,
          head: [["Nombre", "Sexo", "Edad", "Raza", "Peso", "Volumen", "Observaciones"]],
          body: data.convivientes?.map(conviviente => [
            conviviente.nombre || 'No disponible',
            conviviente.sexo || 'No disponible',
            conviviente.edad || 'No disponible',
            conviviente.raza || 'No disponible',
            conviviente.peso || 'No disponible',
            conviviente.volumen || 'No disponible',
            conviviente.observaciones || 'No disponible',
          ]),
          theme: "grid",
          headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
          bodyStyles: { fontSize: 9 },
        });
    
        //Eventos Adversos - tabla
        autoTable(doc, {
          startY: doc.lastAutoTable.finalY + 5,
          head: [["Eventos Adversos"]],
          body: [
            [data.eventosAdversos && data.eventosAdversos.length > 0 ? 'SI' : 'NO'],
          ],
          theme: "grid",
          headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
          bodyStyles: { fontSize: 9 }
        });
        
        // Tabla con detalles de los eventos adversos
        autoTable(doc, {
          startY: doc.lastAutoTable.finalY,
          head: [["Fecha", "Descripción", "Severidad", "Acción Tomada", "Notificador"]],
          body: data.eventosAdversos?.map(evento => [
            evento.fecha || 'No disponible',
            evento.descripcion || 'No disponible',
            evento.severidad || 'No disponible',
            evento.accionTomada || 'No disponible',
            evento.notificador || 'No disponible',
          ]) || [],
          theme: "grid",
          headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
          bodyStyles: { fontSize: 9 },
        });
        autoTable(doc, {
          startY: doc.lastAutoTable.finalY + 5,
          head: [["Posible Relación con el Tratamiento", "Exclusión"]],
          body: data.eventosAdversos?.map(evento => [
            evento.relacionTratamiento || 'No disponible',
            data.rea_exclusion === true ? 'SI' : (data.rea_exclusion === false ? 'NO' : 'No disponible'),
          ]) || [],
          theme: "grid",
          headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
          bodyStyles: { fontSize: 9 },
          
        });
        autoTable(doc, {
          startY: doc.lastAutoTable.finalY,
          head: [["Tratamiento Concomitante", "Alta Clinica", "Observaciones Generales"]],    
          body: [
            [
            data.rea_trataConcomitante || "No disponible", 
            data.rea_altaClinica || "No disponible", 
            data.rea_observaciones || "No disponible",
            ],
          ],
          
          theme: "grid",
          headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
          bodyStyles: { fontSize: 9 },
          didDrawPage: function (data) {
            pageNumber++;
            addPageNumber();
            if (pageNumber > 1) {
              drawPageBorder(); 
            }
            const yPosition = pageHeight - marginBottom +4;
            doc.setFontSize(8);
            doc.setFont("times", "normal");
            // Pie de página - parte izquierda
            doc.text("Powered by Kaizen Software", marginLeft, yPosition);
            // Pie de página - parte central
            doc.text("www.app-alianzainvet.com.ar", pageWidth / 2, yPosition, { align: "center" });
          }
        });
    
        // // Firmas
        // addText("Firma: Investigador Secundario", 10, doc.lastAutoTable.finalY + 15);
        // addText("Firma: Investigador Principal", 80, doc.lastAutoTable.finalY + 15);
        // addText("Firma: Monitor del Ensayo", 150, doc.lastAutoTable.finalY + 15);
    
        // Guardar o mostrar el PDF
        const pdfBlob = doc.output("blob");
        return URL.createObjectURL(pdfBlob);

        window.open(pdfUrl, "_blank");
      };
    
      return (
        <div>
          <button onClick={generatePdf}>Generar PDF</button>
        </div>
      );
    
    };
    PdfFormulariosRC.propTypes = {
      id: PropTypes.string.isRequired,
    };
    
    export default PdfFormulariosRC;
    