import { useEffect, useState, useContext } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from 'react-router-dom';
import { UserContext } from "context/user";

// Components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import { Card, Icon, Tabs, Tab, CircularProgress } from "@mui/material";

// Data
import { MessageManager } from "context";
import { useNavigate } from "react-router-dom";
import Cotizacion from "../Cotizacion";
import Fases from "../Fases";
import ProyectoDetalles from "../ProyectoDetalles";
import Medicaciones from "../Medicaciones";
import EventosAdversos from "../EventosAdversos";
import ModalMensajeFinal from "../ModalMensajeFinal";
import PdfModal from "../PDF/PdfModal";
import PdfFormularioRC from "../PDF/PdfFormularioRC";

const GET_PROYECTO = gql`
  query getProyecto($id: ID!) {
    proyecto(id: $id) {
      id
      nombre
      
    }
  }
`;

const SAVE_FRC_FORM = gql`
  mutation saveFrcForm($input: FrcFormInput!) {
    saveFrcForm(input: $input) {
      id
    }
  }
`;

const GET_FOTOS = gql`
  query GetFotos($id: ID!) {
    getFrcForm(id: $id) {
      documentos {
        id
        file
      }
    }
  }
`;

const ADD_FRC_FORM_FILE = gql`
  mutation addFrcFormFile($input: FrcFormFileInput!) {
    addFrcFormFile(input: $input) {
      id
      nombre
      file
      createdAt
    }
  }
`;
const GET_CURRENT_USER = gql`
  query getCurrentUser {
    currentUser {
      id
      MP
    }
  }
`;

const FormFrc = () => {
  const { id } = useParams();
  const [proyecto, setProyecto] = useState({});
  const [step, setStep] = useState(0);
  const [loadingSaveAll, setLoadingSaveAll] = useState(false);
  const [modalMensajeFinal, setModalMensajeFinal] = useState(false);
  const [textoFinal, setTextoFinal] = useState({ titulo: "", descripcion: "" });
  const navigate = useNavigate();
  const { handleSnackbar } = useContext(MessageManager);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [fotos, setFotos] = useState([]);
  const [addFrcFormFile] = useMutation(ADD_FRC_FORM_FILE);
  const { user } = useContext(UserContext);

  console.log("Usuarioooooo>>>>>>>>>>>>:",user)
  const { data: currentUserData, loading: currentUserLoading, error } = useQuery(GET_CURRENT_USER, {
    skip: !user,
  });
  
  // Acceder al MP del usuario actual
  const MP = currentUserData?.currentUser?.MP;

  const steps = [
    { title: "Inicio", icon: "pets" },
    { title: "Datos Parásitos", icon: "bug_report" },
    { title: "Examen General", icon: "rule" },
    { title: "Medicaciones y Convivientes", icon: "medications" },
    { title: "Registro de Eventos", icon: "event_note" },
  ];

  const { data, loading } = useQuery(GET_PROYECTO, {
    variables: { id },
    skip: !id,
  });
  useEffect(() => {
    if (data) {
      setProyecto(data.proyecto);
    }
  }, [data]);

  const { data: fotosData, loading: loadingFotos } = useQuery(GET_FOTOS, {
    variables: { id },
  });
  useEffect(() => {
    if (fotosData?.getFrcForm?.documentos) {
      setFotos(fotosData.getFrcForm.documentos);  // Guardar las fotos obtenidas
    }
  }, [fotosData])

  const [saveFrcForm] = useMutation(SAVE_FRC_FORM);


  const generatePdf = (proyectoData) => {
    // Propiedades a pasar al PDF
    const pdfData = {

      //idFormulario: proyectoData.id,
      proyectoId: proyectoData.proyectoId,
      nombre: proyectoData.nombre,
      propietario: proyectoData.propietario || 'N/A',
      mp: proyectoData.mp || 'N/A',
      contacto: proyectoData.contacto || 'N/A',
      provincia: proyectoData.provincia || 'N/A',
      localidad: proyectoData.localidad || 'N/A',
      especie: proyectoData.idEspecie || 'N/A',
      raza: proyectoData.idRaza || 'N/A',
      pelaje: proyectoData.pelaje || 'N/A',
      sexo: proyectoData.sexo || 'N/A',
      observaciones: proyectoData.observaciones || 'N/A',
      fotos: fotos,
    };

    const pdfGenerator = PdfFormularioRC({ data: proyectoData });
    const pdfGeneratedUrl = pdfGenerator.generatePdf();
    if (!pdfGeneratedUrl) {
      handleSnackbar("Error al generar el PDF", "error");
      return;
    }
    setPdfUrl(pdfGeneratedUrl);
    setOpenPdfModal(true);
  };

  const downloadPdf = async (pdfUrl) => {
    const response = await fetch(pdfUrl);
    const blob = await response.blob();
    return new File([blob], "formulario.pdf", { type: "application/pdf" });
  };
  
  const handleFinalSave = async () => {
    const { provincia, localidad, ...resto } = proyecto;
    setLoadingSaveAll(true);
  
    try {
      const input = {
        ...resto,
        proyectoId: Number(id),
        mp: parseInt(proyecto.mp, 10),
        mr_antecedentes: Boolean(proyecto.mr_antecedentes),
        rea_posibleRelacion: Boolean(proyecto.rea_posibleRelacion),
        rea_exclusion: Boolean(proyecto.rea_exclusion),
        idProvincia: provincia ? Number(provincia) : null,
        idLocalidad: localidad ? Number(localidad) : null,
        eventosAdversos: resto.eventosAdversos || [],
        convivientes: resto.convivientes || [],
      };
  
      delete input.__typename;
      delete input.id;
  
      // Guardar el formulario en la base de datos
      const { data } = await saveFrcForm({ variables: { input } });
  
      // Después de guardar el formulario, generar PDF
      const pdfData = {
        proyectoId: proyecto.proyectoId,
        nombre: proyecto.nombre,
        propietario: proyecto.propietario || 'N/A',
        mp: proyecto.mp || 'N/A',
        contacto: proyecto.contacto || 'N/A',
        provincia: proyecto.provincia || 'N/A',
        localidad: proyecto.localidad || 'N/A',
        especie: proyecto.idEspecie || 'N/A',
        raza: proyecto.idRaza || 'N/A',
        pelaje: proyecto.pelaje || 'N/A',
        sexo: proyecto.sexo || 'N/A',
        observaciones: proyecto.observaciones || 'N/A',
        fotos: fotos,
      };
  
      const pdfGenerator = PdfFormularioRC({ data: pdfData });
      const pdfGeneratedUrl = pdfGenerator.generatePdf();
  
      if (!pdfGeneratedUrl) {
        handleSnackbar("Error al generar el PDF", "error");
        return;
      }
  
      // Descargar y convertir el PDF generado en un objeto File
      const pdfFile = await downloadPdf(pdfGeneratedUrl);
  
      // Crear el input para la mutación
      const fileInput = {
        file: [pdfFile],  // array (como lo requiere la mutación)
        idProducto: data.saveFrcForm.id,  // asociar el archivo al formulario guardado
      };
  
      // Agregar el archivo PDF usando la mutación addFrcFormFile
      await addFrcFormFile({ variables: { input: fileInput } });
  
      handleSnackbar("Formulario guardado correctamente, PDF agregado", "success");
      setModalMensajeFinal(true);
      setTextoFinal({ titulo: "Éxito", descripcion: "Los datos se han guardado correctamente." });
      navigate("/proyectos");
    } catch (error) {
      console.error("Error al guardar el formulario:", error);
      handleSnackbar("Error al guardar el formulario", "error");
    } finally {
      setLoadingSaveAll(false);
    }
  };
  

  const handleOpenPdf = () => {
    generatePdf(proyecto);
  };

  const handleCloseModal = () => {
    setOpenPdfModal(false);
    setPdfUrl(null);
  };


  const validateStep = () => {
    switch (step) {
      case 0:
        if (!proyecto.profesional || !proyecto.propietario || !proyecto.nombre || !proyecto.provincia || !proyecto.localidad || !proyecto.pelaje || !proyecto.sexo || !proyecto.idEspecie || !proyecto.idRaza) {
          handleSnackbar("Por favor complete todos los campos obligatorios", "error");
          return false;
        }
        break;
      case 1:
        if (!proyecto.ecto_nroPulgas || !proyecto.ecto_nroGarrapatas || !proyecto.endo_idEspecie || !proyecto.endo_formulacionRecibida || !proyecto.endo_fechaTratamiento) {
          handleSnackbar("Las Por favor complete todos los campos obligatorios", "error");
          return false;
        }
        break;
      case 2:
        if (!proyecto.eog_peso || !proyecto.eog_mucosas || !proyecto.eog_linfodulos || !proyecto.eog_temperatura || !proyecto.eog_sensorio || !proyecto.eog_frecuenciaRespiratoria || !proyecto.eog_frecuenciaCardiaca
          || !proyecto.eog_hidratacion || !proyecto.eog_consumo || !proyecto.eog_celo || !proyecto.eog_fechaInicio
        ) {
          handleSnackbar("Por favor complete los campos obligatorios de especie y raza", "error");
          return false;
        }
        break;
        

      default:
        break;
    }
    return true;
  };

  const handlePreviousStep = () => {
    setStep(prevStep => Math.max(prevStep - 1, 0)); 
  };
  const handleNextStep = () => {
    if (validateStep()) {
      setStep(step + 1); //Avanza si los campos obligatorios estan completos
    }
  };

  if (currentUserLoading) return <p>Cargando...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (loading) return <CircularProgress />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox p={3}>
              <SoftBox pb={3}>
                {step === 0 && <ProyectoDetalles proyecto={proyecto} setProyecto={setProyecto} MP={MP} />}
                {step === 1 && <Fases proyecto={proyecto} setProyecto={setProyecto} />}
                {step === 2 && <Cotizacion proyecto={proyecto} setProyecto={setProyecto} />}
                {step === 3 && <Medicaciones  proyecto={proyecto} setProyecto={setProyecto} />}
                {step === 4 && <EventosAdversos proyecto={proyecto} setProyecto={setProyecto} />}
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between" mt={2}>
                <SoftBox sx={{ display: "flex" }}>
                  <Tabs value={step} TabIndicatorProps={{ style: { display: "none" } }} disabled>
                    {steps.map((thisStep, index) => (
                      <Tab
                        sx={{ px: 2 }}
                        key={index}
                        icon={<Icon>{thisStep.icon}</Icon>}
                        label={thisStep.title}
                      />
                    ))}
                  </Tabs>
                </SoftBox>
                <SoftBox display="flex" alignItems="center">
                  {step > 0 && (
                    <SoftButton
                      variant="outlined"
                      color="primary"
                      onClick={handlePreviousStep}
                      style={{ marginRight: "8px" }}
                    >
                      Anterior
                    </SoftButton>
                  )}
                  {step === steps.length - 1 && (
                  <SoftButton variant="outlined" color="primary" onClick={handleOpenPdf} style={{ marginRight: "8px" }}>
                    Ver PDF
                  </SoftButton>
                  )}
                  {step < steps.length - 1 && (
                    <SoftButton variant="gradient" color="primary" onClick={handleNextStep} disabled={loadingSaveAll} style={{ marginLeft: '8px' }}>
                      Siguiente
                    </SoftButton>
                  )}
                  {step === steps.length - 1 && (
                    <SoftButton variant="gradient" color="success" onClick={handleFinalSave} disabled={loadingSaveAll} style={{ marginLeft: '8px' }}>
                      Guardar {loadingSaveAll && <CircularProgress size={15} />}
                    </SoftButton>
                  )}
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
        {/* <ModalMensajeFinal
          open={modalMensajeFinal}
          handleClose={() => setModalMensajeFinal(false)}
          titulo={textoFinal.titulo}
          descripcion={textoFinal.descripcion}
        /> */}
        <PdfModal
          open={openPdfModal}
          handleClose={handleCloseModal}
          pdfUrl={pdfUrl}
        />
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FormFrc;
