import React, { useContext, useEffect, useState } from 'react';
import { Modal, Fade, Card, Grid, InputLabel, Select, MenuItem, CircularProgress, Checkbox, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import { useQuery, useMutation, gql, useLazyQuery } from '@apollo/client';
import { MessageManager } from 'context';

const GET_CLIENTES = gql`
  query {
    clientes {
      id
      nombre
      apellido
      documento
    }
  }
`;

const GET_PROYECTO = gql`
  query proyecto($id: ID!) {
    proyecto(id: $id) {
      id
      nombre
      idCliente
      cliente{
        id
        nombre
        apellido
      }
      fechaInicio
      fechaFinal
      monto
      proveedores {   
        id
        tipoProveedor
        MP
        razonSocial
        nombre
        apellido
      }
    }
  }
`;

const GET_PROVEEDORES = gql`
  query getProveedores {
    proveedores {
      id
      tipoProveedor
      MP
      razonSocial
      nombre
      apellido
      }
      }
    `;


const UPDATE_PROYECTO = gql`
  mutation updateProyecto($id: ID!, $input: ProyectoInput!) {
    updateProyecto(id: $id, input: $input) {
      id
    }
  }
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '90%',
  width: { xs: '90%', sm: '70%', xxl: '50%' },
  overflow: 'auto',
  p: 3,
};

export default function ModalEditProyecto({ open, onClose, onEdit, proyectoId }) {
  const { handleSnackbar } = useContext(MessageManager);
  const [formData, setFormData] = useState({
    proyecto: '',
    cliente: '',
    fechaInicio: '',
    fechaFinal: '',
    monto: '',
    proveedores: [],
  });
  const [notification, setNotification] = useState({ open: false, message: '' });

  // Consulta para obtener los clientes
  const { data: clientesData, loading: loadingClientes, error: errorClientes } = useQuery(GET_CLIENTES, { skip: !open });
  const { data: proveedoresData, loading: proveedoresLoading, error: proveedoresError } = useQuery(GET_PROVEEDORES, { skip: !open });
  // const { data: proyectoData, loading: loadingProyecto, error: errorProyecto } = useQuery(GET_PROYECTO, {
  //   variables: { id: proyectoId },
  //   fetchPolicy: "no-cache",
  //   skip: !proyectoId || !open,
  //   onCompleted: (data) => {
  //     if (data?.proyecto) {
  //       setFormData({
  //         proyecto: data.proyecto.nombre,
  //         cliente:10,
  //         fechaInicio: data.proyecto.fechaInicio,
  //         fechaFinal: data.proyecto.fechaFinal,
  //         monto: data.proyecto.monto,
  //       });
  //     }
  //   },
  // });

  const [getDataProyecto, { data: infoProyecto, loading: loadingInfoProyecto, error: errorInfoProyecto }] = useLazyQuery(
    gql`
    query proyectos($id: ID!) {
      proyecto(id: $id) {
        id
        nombre
        idCliente
        cliente{
          id
          nombre
          apellido
        }
        fechaInicio
        fechaFinal
        monto
        proveedores {
          id
          tipoProveedor
          MP
          razonSocial
          nombre  
          apellido
        }
      }
    }
    `, {
    fetchPolicy: "no-cache",
  }

  );

  const [updateProyecto] = useMutation(UPDATE_PROYECTO, {
    onCompleted: () => {
      onEdit(); // Llamar a onEdit para actualizar el estado en el componente padre
      resetForm();
      showNotification('Proyecto actualizado correctamente');
      onClose(); // Cerrar el modal después de la actualización
    },
    onError: (error) => {
      showNotification(`Error: ${error.message}`);
    },
  });

  const resetForm = () => {
    setFormData({
      proyecto: '',
      cliente: '',
      fechaInicio: '',
      fechaFinal: '',
      monto: '',
      proveedores: [],
    });
  };

  const showNotification = (message) => {
    setNotification({ open: true, message });
    setTimeout(() => setNotification({ open: false, message: '' }), 2000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'proveedores') {
      setFormData({
        ...formData,
        proveedores:  value,
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  

  const handleSubmit = async () => {
    if (!formData.proyecto || !formData.cliente || !formData.fechaInicio || !formData.fechaFinal || !formData.monto) {
      alert("Por favor, complete todos los campos obligatorios.");
      return;
    }

    const montoNumerico = parseFloat(formData.monto);
    if (isNaN(montoNumerico)) {
      alert("El monto debe ser un número válido.");
      return;
    }

    try {
      await updateProyecto({
        variables: {
          id: proyectoId,
          input: {
            nombre: formData.proyecto,
            idCliente: parseInt(formData.cliente),
            fechaInicio: formData.fechaInicio,
            fechaFinal: formData.fechaFinal,
            monto: montoNumerico,
            proveedores: formData.proveedores,
          },
        },
      });

      handleSnackbar("Proyecto actualizado correctamente", "success");
    } catch (error) {
      console.error("Error updating project:", error);
      handleSnackbar("Ocurrió un error al actualizar el proyecto. Inténtalo de nuevo.", "error");
    }
  };

  useEffect(() => {
    if (proyectoId) {
      getDataProyecto({ variables: { id: proyectoId } }).then((result) => {
        if (result?.data?.proyecto) {
          setFormData({
            proyecto: result.data.proyecto.nombre,
            cliente: result.data.proyecto.idCliente,
            fechaInicio: result.data.proyecto.fechaInicio,
            fechaFinal: result.data.proyecto.fechaFinal,
            monto: result.data.proyecto.monto,
            proveedores: Array.isArray(result.data.proyecto.proveedores) ? result.data.proyecto.proveedores.map(proveedor => proveedor.id) : [],  

          });
        }
      }).catch((error) => {
        console.error("Error fetching project:", error);
      })
    }
  }, [open, proyectoId]);


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" mb={2}>
              <SoftTypography variant="h6">Editar Proyecto</SoftTypography>
            </SoftBox>
            {loadingClientes || loadingInfoProyecto ? (
              <SoftBox display="flex" justifyContent="center" mt={2}>
                <CircularProgress />
              </SoftBox>
            ) : errorClientes ? (
              <SoftTypography color="error">Error al cargar los clientes</SoftTypography>
            ) : errorInfoProyecto ? (
              <SoftTypography color="error">Error al cargar el proyecto</SoftTypography>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel htmlFor="proyecto">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre del Proyecto
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    name="proyecto"
                    value={formData.proyecto}
                    onChange={handleChange}
                    placeholder="Ingrese el nombre del proyecto"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="cliente">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Cliente
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="cliente"
                    value={formData.cliente}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Seleccione un cliente
                    </MenuItem>
                    {(clientesData?.clientes || []).map((cliente) => (
                      <MenuItem key={cliente.id} value={cliente.id}>
                        {`${cliente.nombre} ${cliente.apellido || ''} - ${cliente.documento}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fechaInicio">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Fecha Inicio
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    name="fechaInicio"
                    type="date"
                    value={formData.fechaInicio}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fechaFinal">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Fecha Final
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    name="fechaFinal"
                    type="date"
                    value={formData.fechaFinal}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="proveedores">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Proveedores
                          </SoftTypography>
                        </InputLabel>
                        <Select
                          name="proveedores"
                          multiple
                          value={formData.proveedores || []}  
                          onChange={handleChange}
                          displayEmpty
                          renderValue={(selected) => {
                            if (selected.length === 0) return 'Seleccione proveedores';  // Mensaje por defecto si no hay selección
                            const selectedNames = proveedoresData.proveedores.filter(p => selected.includes(p.id)).map(p => p.nombre+' '+p.apellido);
                            return selectedNames.join(', ');
                          }}
                          fullWidth
                          sx={{
                            fontSize: '0.875rem',
                            '& .MuiInputBase-root': {
                              fontSize: '1rem',
                            },
                          }}
                        >
                          <MenuItem value="" disabled>
                            Seleccione proveedores
                          </MenuItem>
                          {(proveedoresData?.proveedores || []).map((proveedor) => (
                            <MenuItem key={proveedor.id} value={proveedor.id}>
                              <Checkbox checked={formData.proveedores.includes(proveedor.id)} />
                              <ListItemText primary={`${proveedor.nombre} ${proveedor.apellido || ''}`} />
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12}>
                  <InputLabel htmlFor="monto">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Monto
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    name="monto"
                    value={formData.monto}
                    onChange={handleChange}
                    placeholder="Ingrese el monto"
                  />
                </Grid>
              </Grid>
            )}
            <SoftBox display="flex" justifyContent="end" mt={2}>
              <SoftButton onClick={onClose} color="secondary" variant="outlined">
                Cancelar
              </SoftButton>
              <SoftButton onClick={handleSubmit} color="primary" variant="contained" sx={{ ml: 2 }}>
                Guardar
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalEditProyecto.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  proyectoId: PropTypes.string.isRequired,
};
