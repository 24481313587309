import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import Logo from "assets/images/AlianzaInvet.png";

const PDFPlanillasVR = ({ registro = {}, visitas = [], checkboxState = {} }) => {
  const doc = new jsPDF('p', 'mm', 'a4');
  const marginLeft = 15;
  const marginTop = 15;
  const marginBottom = 10;
  let yPosition = marginTop;

  // Función reutilizable para agregar texto
  const addText = (text, x, y, fontSize = 10, fontStyle = "normal") => {
    doc.setFontSize(fontSize);
    doc.setFont("times", fontStyle);
    doc.text(text, x, y);
  };

  const agregarNumeroPagina = () => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    doc.text(`Página ${pageCount}`, 180, 290); 
    
  };

  const addTextWithUnderline = (text, x, y, fontSize = 12, fontStyle = "normal") => {
    doc.setFontSize(fontSize);
    doc.setFont("times", fontStyle);
    doc.text(text, x, y);
    // Subrayar
    const textWidth = doc.getTextWidth(text);
    const lineHeight = 2; 
    doc.line(x, y + lineHeight, x + textWidth, y + lineHeight);
  };

  //Marco
  const drawPageBorder = () => {
    const pageWidth = 210; 
    const pageHeight = 297; 
    const margin = 10; 
    doc.setLineWidth(0.5);
    doc.setDrawColor(0, 200, 0); 
    doc.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin);
  };

  doc.addImage(Logo, "PNG", 85, marginTop, 35, 25); // Ruta, tipo, x, y, ancho, alto
  drawPageBorder();
  const drawCheckbox = (x, y, isChecked = false) => {
    const checkboxSize = 4; 
    doc.rect(x, y, checkboxSize, checkboxSize);
    if (isChecked) {
      doc.setLineWidth(1);
      doc.line(x, y, x + checkboxSize, y + checkboxSize);
      doc.line(x + checkboxSize, y, x, y + checkboxSize);
    }
  };
  
  // Encabezado
  yPosition = marginTop + 35;
  addTextWithUnderline("PROTOCOLO: PLANILLA DE REGISTRO DE VISITAS PARA RECUENTO PARASITARIO", marginLeft + 7, yPosition);
  yPosition += 10;

  autoTable(doc, {
    head: [
      [
        "Campo",
        "Información",
      ],
    ],
    body: [
      ["Código", "P-EFI-AC-004-V0.1"],
      ["Fecha de Vigencia", registro.fechaVigencia || "" ],
      ["Próxima Revisión", registro.proximaRevision || ""],
      ["Versión", "0.1"],
      ["CRONOGRAMA DE VISITAS PARA LOS RECUENTOS DE PULGAS Y GARRAPATAS"],
      ["Laboratorio", registro.laboratorio || ""],
      [  "Prueba", 
        checkboxState.prueba ? "✔" : "",
        "Referencia", 
        checkboxState.referencia ? "✔" : "",
      ],
    ],
    startY: yPosition,
    theme: "grid",
    styles: {
      fontSize: 9,
      fillColor: [240, 240, 240], 
    },
    alternateRowStyles: {
      fillColor: [255, 255, 255],
    },
    headStyles: {
      fillColor: [100, 100, 100],
      textColor: [255, 255, 255],
    },
  });

  yPosition = doc.lastAutoTable.finalY + 15;

  // // Información general
  // addText("Código:", marginLeft, yPosition);
  // addText(registro.codigo || "", marginLeft + 15, yPosition);
  // yPosition += 10;

  // addText("Fecha de Vigencia:", marginLeft + 0, yPosition);
  // addText(registro.fechaVigencia || "", marginLeft + 30, yPosition);
  // yPosition += 10;

  // addText("Próxima Revisión:", marginLeft + 0, yPosition);
  // addText(registro.proximaRevision || "", marginLeft + 30, yPosition);
  // yPosition += 10;

  // // Cronograma de Visitas
  // doc.setFont("times", "bold");
  // addText("CRONOGRAMA DE VISITAS PARA LOS RECUENTOS DE PULGAS Y GARRAPATAS", marginLeft + 20, yPosition);
  // yPosition += 10;


  // const checkboxX = marginLeft + 15;
  // const labelX = marginLeft + 35;

  // addText("Prueba:", marginLeft, yPosition);
  // drawCheckbox(checkboxX, yPosition - 2, checkboxState.prueba); // Si está true, marcarlo

  // addText("Referencia:", labelX , yPosition); 
  // drawCheckbox(labelX + 20, yPosition - 2, checkboxState.referencia); // Checkbox de "Referencia"

  // yPosition += 10;

  const visitRows = visitas.map((visita) => [
    visita.dia || "",
    visita.fecha || "",
    visita.pulgas || "",
    visita.garrapatas || "",
    visita.localizacionGarrapatas || "",
  ]);

  // Tabla de visitas
  autoTable(doc, {
    head: [["Día", "Fecha", "Pulgas", "Garrapatas", "Localización Garrapatas"]],
    body: visitRows,
    startY: yPosition,
    theme: "striped",
    headStyles: { fillColor: [100, 100, 100], textColor: [255, 255, 255] },
    bodyStyles: { fontSize: 9, cellPadding: 4 },
    cellPadding: 4,
  });
  yPosition = doc.lastAutoTable.finalY + 10;

  autoTable(doc, {
    // head: [
    //   [
    //     "Campo",
    //     "Información",
    //   ],
    // ],
    body: [
      ["Investigador Responsable:", registro.investigadorResponsable || "" ],
      ["Investigador Secundario o Coinvestigador:",registro.investigadorSecundario || ""],
      ["Monitor", registro.monitor || ""],
      
    ],
    startY: yPosition,
    theme: "grid",
    styles: {
      fontSize: 9,
      fillColor: [240, 240, 240], 
    },
    alternateRowStyles: {
      fillColor: [255, 255, 255],
    },
    headStyles: {
      fillColor: [100, 100, 100],
      textColor: [255, 255, 255],
    },
  });
  
  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;
  
  const yPositionPie = pageHeight - marginBottom + 4;
  // Configuración del pie de página
  doc.setFontSize(8);
  doc.setFont("times", "normal");
  
  // Pie de página - parte izquierda
  doc.text("Powered by Kaizen Software", marginLeft, yPositionPie);
  
  // Pie de página - parte central
  doc.text("www.app-alianzainvet.com.ar", pageWidth / 2, yPositionPie, { align: "center" });
  
  // // Firmas y responsables
  // addText("Investigador Responsable:", marginLeft, yPosition);
  // addText(registro.investigadorResponsable || "", marginLeft + 70, yPosition);
  // yPosition += 10;

  // addText("Investigador Secundario o Coinvestigador:", marginLeft, yPosition);
  // addText(registro.investigadorSecundario || "", marginLeft + 70, yPosition);
  // yPosition += 10;

  // addText("Monitor:", marginLeft, yPosition);
  // addText(registro.monitor || "", marginLeft + 70, yPosition);

  agregarNumeroPagina();

  return doc.output("dataurlnewwindow");
};

export default PDFPlanillasVR;
