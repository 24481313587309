import React, { useState, useEffect } from "react";
import { Grid, InputLabel, Checkbox, FormControlLabel, IconButton } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import jsPDF from "jspdf";
import DeleteIcon from "@mui/icons-material/Delete";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { useMutation, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import PDFRegistroHelmintos from "../PDF/PDFRegistroHelmintos";
import { useNavigate } from "react-router-dom";

const SAVE_HELMINTH_REGISTRATION = gql`
  mutation savePefiac005v01Form($input: Pefiac005v01FormInput!) {
    savePefiac005v01Form(input: $input) {
      id
    }
  }
`;

const RegistroHelmintos = () => {
  const { id } = useParams();
  const [formulario, setFormulario] = useState({
    idFrcForm: id,
    fechaVigencia: "",
    fechaRevision: "",
    profesional: "",
    laboratorio: "",
    can: false,
    fel: false,
    prueba: false,
    referencia: false,
  });

  const [secciones, setSecciones] = useState([
    {
      id: 1,
      identificacionAnimal: "",
      especieParasita: "",
      valoresHPG: {
        basal: { fecha: "", valor: "" },
        dia15: { fecha: "", valor: "" },
        dia30: { fecha: "", valor: "" },
      },
    },
  ]);

  const [loading, setLoading] = useState(false);
  const [saveHelminthRegistration] = useMutation(SAVE_HELMINTH_REGISTRATION);

  const handleChange = (key, value) => {
    setFormulario((prev) => ({ ...prev, [key]: value }));
  };

  const handleChangeSection = (id, field, value) => {
    setSecciones((prev) =>
      prev.map((section) =>
        section.id === id ? { ...section, [field]: value } : section
      )
    );
  };

  const handleHPGChange = (id, dia, field, value) => {
    setSecciones((prev) =>
      prev.map((section) =>
        section.id === id
          ? {
            ...section,
            valoresHPG: {
              ...section.valoresHPG,
              [dia]: { ...section.valoresHPG[dia], [field]: value },
            },
          }
          : section
      )
    );
  };

  const handleFieldChange = (field) => {
    setFormulario((prev) => {
      if (field === "can" || field === "fel") {
        return { ...prev, can: field === "can" ? !prev.can : false, fel: field === "fel" ? !prev.fel : false };
      } else if (field === "prueba" || field === "referencia") {
        return { ...prev, prueba: field === "prueba" ? !prev.prueba : false, referencia: field === "referencia" ? !prev.referencia : false };
      }
      return { ...prev, [field]: !prev[field] };
    });
  };
  

  const handleAddSection = () => {
    if (secciones.length < 10) {
      setSecciones((prev) => [
        ...prev,
        {
          id: prev.length + 1,
          identificacionAnimal: "",
          especieParasita: "",
          valoresHPG: {
            basal: { fecha: "", valor: "" },
            dia15: { fecha: "", valor: "" },
            dia30: { fecha: "", valor: "" },
          },
        },
      ]);
    }
  };

  const handleRemoveSection = (id) => {
    setSecciones((prev) => prev.filter((section) => section.id !== id));
  };

  const handleGeneratePDFPreview = () => {
    const pdfDataUrl = PDFRegistroHelmintos({
      registro: formulario,
      visitas: secciones,
      checkboxState: formulario,
    });
    window.open(pdfDataUrl, "_blank"); // Abre la vista previa del PDF en una nueva ventana
  };

  const handleSaveAndGeneratePDF = () => {
    const isValid = secciones.every(
      (section) => section.valoresHPG.basal.fecha && section.valoresHPG.basal.valor
    );
  
    if (!isValid) {
      alert("Por favor, complete la fecha y valor de HPG Basal.");
      return;
    }
  
    const input = {
      idFrcForm: formulario.idFrcForm,
      fechaVigencia: formulario.fechaVigencia,
      fechaRevision: formulario.fechaRevision,
      profesional: formulario.profesional,
      laboratorio: formulario.laboratorio,
      can: formulario.can,
      fel: formulario.fel,
      prueba: formulario.prueba,
      referencia: formulario.referencia,
      secciones: secciones.map((section) => ({
        identificacionAnimal: section.identificacionAnimal,
        especieParasita: section.especieParasita,
        basalFecha: section.valoresHPG.basal.fecha,
        basalValor: section.valoresHPG.basal.valor,
        dia15Fecha: section.valoresHPG.dia15.fecha || null,
        dia15Valor: section.valoresHPG.dia15.valor || null,
        dia30Fecha: section.valoresHPG.dia30.fecha || null,
        dia30Valor: section.valoresHPG.dia30.valor || null,
      })),
    };
  
    console.log("Enviando datos al servidor:", JSON.stringify(input, null, 2));
  
    setLoading(true);
    saveHelminthRegistration({ variables: { input } })
      .then((response) => {
        console.log("Datos guardados con éxito:", response.data);
        alert("Datos guardados y PDF generado correctamente.");
      })
      .catch((error) => {
        console.error("Error al guardar los datos:", error.message || error);
        alert(`Hubo un error al guardar los datos: ${error.message || "Error desconocido."}`);
      })
      .finally(() => setLoading(false));
  };
  
  const navigate = useNavigate();
  

  useEffect(() => {
    if (id) {
      setFormulario((prevState) => ({
        ...prevState,
        idFrcForm: id,
      }));
    }
    }, [id]);  

  // const handleSave = () => {
  //   const isValid = secciones.every(section => 
  //     section.valoresHPG.basal.fecha && section.valoresHPG.basal.valor
  //   );
  //   if (!isValid) {
  //     alert("Por favor, complete la fecha y valor de HPG Basal.");
  //     return; 
  //   }
  //   const input = {
  //     idFrcForm: formulario.idFrcForm,
  //     profesional: formulario.profesional,
  //     laboratorio: formulario.laboratorio,
  //     can: formulario.can,
  //     fel: formulario.fel,
  //     prueba: formulario.prueba,
  //     referencia: formulario.referencia,
  //     secciones: secciones.map((section) => ({
  //       identificacionAnimal: section.identificacionAnimal,
  //       especieParasita: section.especieParasita,
  //       basalFecha: section.valoresHPG.basal.fecha,
  //       basalvalor: section.valoresHPG.basal.Valor,
  //       dia15Fecha: section.valoresHPG.dia15.fecha || null,
  //       dia15Valor: section.valoresHPG.dia15.valor || null,
  //       dia30Fecha: section.valoresHPG.dia30.fecha || null,
  //       dia30Valor: section.valoresHPG.dia30.valor || null,
  //     })),
  // //   };

  //   saveHelminthRegistration({ variables: { input } })
  //     .then((response) => {
  //       console.log("Datos guardados con éxito:", response.data);
  //       alert("Datos guardados correctamente.");
  //     })
  //     // .catch((error) => {
  //     //   console.error("Error al guardar los datos:", error);
  //     //   alert("Hubo un error al guardar los datos. Intenta nuevamente.");
  //     // })
  //     .finally(() => setLoading(false)); 
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox>
        <SoftBox display="flex" justifyContent="flex-end" mb={2}>
                  <SoftButton
                    onClick={() => navigate("/proyectos")} // Redirige a la página de proyectos
                    variant="contained"
                    color="primary"
                  >
                    Volver
                  </SoftButton>
                </SoftBox>
        <SoftTypography variant="h5" fontWeight="bold" mb={2} sx={{ fontSize: "1.2rem" }}>
          Registro de Recuento Parasitario – Helmintos
        </SoftTypography>
        <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
            <InputLabel sx={{ fontSize: '1rem' }}>Fecha Vigencia</InputLabel>
            <SoftInput
              type="date"
              sx={{ fontSize: '0.9rem' }}
              value={formulario.fechaVigencia}
              onChange={(e) => handleChange("fechaVigencia", e.target.value)}
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <InputLabel sx={{ fontSize: '1rem' }}>Fecha Proxima Revision</InputLabel>
            <SoftInput
              type="date"
              sx={{ fontSize: '0.9rem' }}
              value={formulario.fechaRevision}
              onChange={(e) => handleChange("fechaRevision", e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <InputLabel sx={{ fontSize: '1rem' }}>Profesional Actuante</InputLabel>
            <SoftInput
              sx={{ fontSize: '0.9rem' }}
              placeholder="Ingrese nombre"
              value={formulario.profesional}
              onChange={(e) => handleChange("profesional", e.target.value)}
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <InputLabel sx={{ fontSize: '1rem' }}>Laboratorio</InputLabel>
            <SoftInput
              sx={{ fontSize: '0.9rem' }}
              placeholder="Ingrese laboratorio"
              value={formulario.laboratorio}
              onChange={(e) => handleChange("laboratorio", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} ml={2}>
            <SoftBox display="flex" alignItems="center" gap={2}>
              <FormControlLabel
                control={<Checkbox checked={formulario.can} onChange={() => handleFieldChange("can")} />}
                label="Can"
                sx={{ fontSize: '1rem' }}
              />
              <FormControlLabel
                control={<Checkbox checked={formulario.fel} onChange={() => handleFieldChange("fel")} />}
                label="Fel"
                sx={{ fontSize: '1rem' }}
              />
            {/* </SoftBox>
            <SoftBox display="flex" alignItems="center" gap={2}> */}
              <FormControlLabel
                control={<Checkbox checked={formulario.prueba} onChange={() => handleFieldChange("prueba")} />}
                label="Prueba"
                sx={{ fontSize: '1rem' }}
              />
              <FormControlLabel
                control={<Checkbox checked={formulario.referencia} onChange={() => handleFieldChange("referencia")} />}
                label="Referencia"
                sx={{ fontSize: '1rem' }}
              />
            </SoftBox>
          </Grid>


          {secciones.map((section) => (
            <React.Fragment key={section.id}>
              <Grid item xs={12} sm={6}>
                <InputLabel sx={{ fontSize: '1rem' }}>Identificación Animal</InputLabel>
                <SoftInput
                  sx={{ fontSize: '0.9rem' }}
                  placeholder="Ingrese identificación"
                  value={section.identificacionAnimal}
                  onChange={(e) =>
                    handleChangeSection(section.id, "identificacionAnimal", e.target.value)
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel sx={{ fontSize: '1rem' }}>Especie Parásita</InputLabel>
                <SoftInput
                  sx={{ fontSize: '0.9rem' }}
                  placeholder="Ingrese especie"
                  value={section.especieParasita}
                  onChange={(e) =>
                    handleChangeSection(section.id, "especieParasita", e.target.value)
                  }
                />
              </Grid>

              {["basal", "dia15", "dia30"].map((dia) => (
                <Grid item xs={12} sm={4} key={`${section.id}-${dia}`}>
                  <SoftTypography variant="subtitle1" sx={{ fontSize: "1.1rem" }}>
                    {`HPG ${dia.toUpperCase()}`}
                  </SoftTypography>
                  <InputLabel sx={{ fontSize: '1rem' }}>Fecha</InputLabel>
                  <SoftInput
                    type="date"
                    sx={{ fontSize: '0.9rem' }}
                    value={section.valoresHPG[dia].fecha}
                    onChange={(e) =>
                      handleHPGChange(section.id, dia, "fecha", e.target.value)
                    }
                  />
                  <InputLabel sx={{ fontSize: '1rem' }}>Valor</InputLabel>
                  <SoftInput
                    type="number"
                    sx={{ fontSize: '0.9rem' }}
                    value={section.valoresHPG[dia].valor}
                    onChange={(e) =>
                      handleHPGChange(section.id, dia, "valor", e.target.value)
                    }
                  />
                </Grid>
              ))}

              <Grid item xs={12}>
                <IconButton
                  onClick={() => handleRemoveSection(section.id)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}

          <Grid item xs={12}>
            <SoftButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleAddSection}
              disabled={secciones.length >= 10}
              sx={{ mt: 2 }}
            >
              Añadir Sección
            </SoftButton>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center">
            <SoftButton variant="contained" color="primary" onClick={handleSaveAndGeneratePDF} disabled={loading}>
            {loading ? 'Guardando...' : 'Guardar'}
            </SoftButton>
            <SoftButton variant="contained" color="primary" onClick={handleGeneratePDFPreview} sx={{ ml: 2 }}>
              Generar PDF
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
};

export default RegistroHelmintos;
