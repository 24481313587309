// React

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

import ModalDelete from "components/Modals/Delete";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { validateCUIL, validateDNI } from "utils/validations";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "50%" },
  overflow: "auto",
  p: 3,
};

const ADD_PROVEEDOR = gql`
  mutation addProveedor($input: ProveedorInput!) {
    saveProveedor(input: $input) {
      id
    }
  }
`;

const DELETE_PROVEEDOR = gql`
  mutation deleteProveedor($id: ID!) {
    deleteProveedor(id: $id) {
      id
    }
  }
`;

export default function ModalAddProveedor({ open, handleClose, data, refetch }) {
  const [proveedor, setProveedor] = useState();
  const [isEdit, setIsEdit] = useState();
  const { handleSnackbar } = useContext(MessageManager);

  const [addProveedor, { loading: loadingSave }] = useMutation(ADD_PROVEEDOR);

  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);

  const handleSave = () => {
    const mpValue = parseInt(proveedor.MP, 10); // Convertir a entero

    if (isNaN(mpValue)) {
    handleSnackbar("M.P. debe ser un número entero válido", "error");
    return; // Si no es válido, evitamos que se guarde
    }
    addProveedor({
      variables: {
        input: {
          id: proveedor.id || null,
          tipoProveedor: proveedor.tipoProveedor,
          MP: mpValue,
          nombre: proveedor.nombre,
          apellido: proveedor.apellido,
          tipoDocumento: proveedor.tipoDocumento,
          documento: proveedor.documento,
          tipoResponsable: proveedor.tipoResponsable,
          razonSocial: proveedor.razonSocial || null,
          idProvincia: proveedor.provincia,
          idLocalidad: proveedor.localidad,
          idUser: proveedor.idUser,
        },
      },
    })
      .then(() => {
        setProveedor();
        handleSnackbar("Proveedor guardado correctamente", "success");
        handleClose();
      })
      .catch(() => {
        handleSnackbar("Error al agregar el proveedor", "error");
      });
  };

  const handleChanges = (e) => {
    setProveedor({
      ...proveedor,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setProveedor(data);
    setIsEdit(false);
    refetch();
  }, [open, data]);

  useEffect(() => {
    if (proveedor?.provincia) {
      getLocalidades({ variables: { provincia: proveedor?.provincia } }).then((data) => {
        // Si la localidad no pertenece a la provincia seleccionada, se limpia el campo
        if (!data?.data?.localidades?.find((localidad) => localidad.id === proveedor?.localidad)) {
          setProveedor({
            ...proveedor,
            localidad: -1,
          });
        }
      });
    }
  }, [proveedor?.provincia]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" mb={2}>
              <SoftBox>
                <SoftTypography variant="h6">
                  {isEdit ? "Editar proveedor" : "Agregar proveedor"}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <Grid container spacing={2}><Grid item xs={12} sm={1.5}>
                <InputLabel htmlFor="documento">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    M.P.
                    <SoftTypography variant="caption" fontWeight="bold" color="primary">
                      &nbsp;*
                    </SoftTypography>
                  </SoftTypography>
                </InputLabel>
                <SoftInput
                  label="Matricula Profesional"
                  placeholder="Inserte M.P."
                  type="text"  
                  name="M.P."
                  value={proveedor?.MP || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setProveedor({
                        ...proveedor,
                        MP: value, 
                      });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={2.5}>
                <InputLabel htmlFor="tipoProveedor">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Tipo de proveedor
                    <SoftTypography variant="caption" fontWeight="bold" color="primary">
                      &nbsp;*
                    </SoftTypography>
                  </SoftTypography>
                </InputLabel>
                <Select
                  name="tipoProveedor"
                  value={proveedor?.tipoProveedor || -1}
                  onChange={handleChanges}
                >
                  <MenuItem value={-1} disabled>
                    Seleccione uno
                  </MenuItem>
                  <MenuItem value="Particular">Particular</MenuItem>
                  <MenuItem value="Empresa">Empresa</MenuItem>
                </Select>
              </Grid>
              {proveedor?.tipoProveedor === "Empresa" && (
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="razonSocial">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Razon Social
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="razonSocial"
                    placeholder="Inserte la razón social"
                    type="text"
                    name="razonSocial"
                    value={proveedor?.razonSocial || ""}
                    onChange={handleChanges}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={4}>
                <InputLabel htmlFor="nombre">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Nombre
                    <SoftTypography variant="caption" fontWeight="bold" color="primary">
                      &nbsp;*
                    </SoftTypography>
                  </SoftTypography>
                </InputLabel>
                <SoftInput
                  label="nombre"
                  placeholder="Inserte el nombre del proveedor"
                  type="text"
                  name="nombre"
                  value={proveedor?.nombre || ""}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel htmlFor="apellido">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Apellido
                    <SoftTypography variant="caption" fontWeight="bold" color="primary">
                      &nbsp;*
                    </SoftTypography>
                  </SoftTypography>
                </InputLabel>
                <SoftInput
                  label="apellido"
                  placeholder="Inserte el apellido del proveedor"
                  type="text"
                  name="apellido"
                  value={proveedor?.apellido || ""}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel htmlFor="tipoDocumento">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Tipo de documento
                    <SoftTypography variant="caption" fontWeight="bold" color="primary">
                      &nbsp;*
                    </SoftTypography>
                  </SoftTypography>
                </InputLabel>
                <Select
                  name="tipoDocumento"
                  value={proveedor?.tipoDocumento || -1}
                  onChange={handleChanges}
                >
                  <MenuItem value={-1} disabled>
                    Seleccione uno
                  </MenuItem>

                  <MenuItem value={96}>DNI</MenuItem>
                  <MenuItem value={80}>CUIT</MenuItem>
                  <MenuItem value={86}>CUIL</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel htmlFor="documento">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Documento
                    <SoftTypography variant="caption" fontWeight="bold" color="primary">
                      &nbsp;*
                    </SoftTypography>
                  </SoftTypography>
                </InputLabel>
                <SoftInput
                  label="documento"
                  placeholder="Inserte el documento del proveedor"
                  type="text"
                  name="documento"
                  value={proveedor?.documento || ""}
                  error={
                    proveedor?.tipoDocumento === 96 && proveedor?.documento?.length > 0
                      ? !validateDNI(proveedor?.documento)
                      : proveedor?.documento?.length > 0 && !validateCUIL(proveedor?.documento)
                  }
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel htmlFor="tipoResponsable">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Tipo de responsable
                    <SoftTypography variant="caption" fontWeight="bold" color="primary">
                      &nbsp;*
                    </SoftTypography>
                  </SoftTypography>
                </InputLabel>
                <Select
                  name="tipoResponsable"
                  value={proveedor?.tipoResponsable || -1}
                  onChange={handleChanges}
                >
                  <MenuItem value={-1} disabled>
                    Seleccione uno
                  </MenuItem>
                  <MenuItem value={1}>IVA Responsable Inscripto</MenuItem>
                  <MenuItem value={2}>IVA Responsable no Inscripto</MenuItem>
                  <MenuItem value={3}>IVA no Responsable</MenuItem>
                  <MenuItem value={4}>IVA Sujeto Exento</MenuItem>
                  <MenuItem value={5}>Consumidor Final</MenuItem>
                  <MenuItem value={6}>Responsable Monotributo</MenuItem>
                  <MenuItem value={7}>Sujeto no Categorizado</MenuItem>
                  <MenuItem value={8}>Proveedor del Exterior</MenuItem>
                  <MenuItem value={9}>Cliente del Exterior</MenuItem>
                  <MenuItem value={10}>IVA Liberado – Ley Nº 19.640</MenuItem>
                  <MenuItem value={11}>IVA Responsable Inscripto – Agente de Percepción</MenuItem>
                  <MenuItem value={12}>Pequeño Contribuyente Eventual</MenuItem>
                  <MenuItem value={13}>Monotributista Social</MenuItem>
                  <MenuItem value={14}>Pequeño Contribuyente Eventual Social</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={proveedor?.tipoProveedor === "Empresa" ? 4 : 6}>
                <InputLabel htmlFor="provincia">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Provincia
                    <SoftTypography variant="caption" fontWeight="bold" color="primary">
                      &nbsp;*
                    </SoftTypography>
                  </SoftTypography>
                </InputLabel>
                <Select
                  name="provincia"
                  value={proveedor?.provincia || -1}
                  onChange={handleChanges}
                >
                  <MenuItem value={-1} disabled>
                    Seleccione uno
                  </MenuItem>
                  {provincias?.provincias?.map((provincia) => (
                    <MenuItem key={provincia.id} value={provincia.id}>
                      {provincia.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={proveedor?.tipoProveedor === "Empresa" ? 4 : 6}>
                <InputLabel htmlFor="localidad">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Localidad
                    <SoftTypography variant="caption" fontWeight="bold" color="primary">
                      &nbsp;*
                    </SoftTypography>
                  </SoftTypography>
                </InputLabel>
                <Select
                  name="localidad"
                  value={proveedor?.localidad || -1}
                  onChange={handleChanges}
                >
                  <MenuItem value={-1} disabled>
                    Seleccione uno
                  </MenuItem>
                  {localidades?.localidades?.map((localidad) => (
                    <MenuItem key={localidad.id} value={localidad.id}>
                      {localidad.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <SoftBox display="flex" justifyContent="end" mt={2}>
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="sistemasAmarillo"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox>
                <SoftButton
                  color="sistemasNegro"
                  circular
                  disabled={
                    loadingSave ||
                    !proveedor?.tipoProveedor ||
                    !proveedor?.nombre ||
                    !proveedor?.apellido ||
                    !proveedor?.tipoDocumento ||
                    !proveedor?.documento ||
                    !proveedor?.tipoResponsable ||
                    !proveedor?.provincia ||
                    !proveedor?.localidad ||
                    proveedor?.localidad === -1 ||
                    (proveedor?.tipoDocumento === 96 && proveedor?.documento?.length > 0
                      ? !validateDNI(proveedor?.documento)
                      : proveedor?.documento?.length > 0 && !validateCUIL(proveedor?.documento)) ||
                    (proveedor?.tipoProveedor === "Empresa" && !proveedor?.razonSocial)
                  }
                  onClick={handleSave}
                >
                  {loadingSave ? (
                    <CircularProgress size={15} color="white" />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalAddProveedor.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object,
  refetch: PropTypes.func,
};
