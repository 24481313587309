import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Grid, InputLabel, Checkbox, FormControlLabel, Paper, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { jsPDF } from "jspdf";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { useMutation, gql, useQuery } from '@apollo/client';
import { useParams } from "react-router-dom";
import PDFPlanillasVR from '../PDF/PDFPlanillaVisitasR';
import { useNavigate } from 'react-router-dom';



// const GET_FRCFORM = gql`
// query getFrcForm($id: ID!) {
//   frcForm(id: $id) {
//     id
//   }
// }
// `;
const SAVE_PEFIAC003 = gql`
  mutation savePefiac003v01Form($input: Pefiac003v01FormInput!) {
    savePefiac003v01Form(input: $input) {
      id
    }
  }
`;
const RegistroVisitasForm = () => {
  const { id } = useParams();
  // const { data, loading } = useQuery(GET_FRCFORM, {
  //   variables: { id: frcFormId },
  //   //skip: !id,
  // });
  const [registro, setRegistro] = useState({
    idFrcForm: id,
    codigo: '',
    fechaVigencia: '',
    proximaRevision: '',
    identificacionNumero: '',
    identificacionNombre: '',
    identificacionFecha: '',
    investigadorResponsable: '',
    investigadorSecundario: '',
    monitor: '',
  });
  const [visitas, setVisitas] = useState([
    { dia: "Día 0", pulgas: "", garrapatas: "", fecha: "", localizacionGarrapatas: "" },
    { dia: "24 hs", pulgas: "", garrapatas: "", fecha: "", localizacionGarrapatas: "" },
    { dia: "Día 7", pulgas: "", garrapatas: "", fecha: "", localizacionGarrapatas: "" },
    { dia: "Día 15", pulgas: "", garrapatas: "", fecha: "", localizacionGarrapatas: "" },
    { dia: "Día 21", pulgas: "", garrapatas: "", fecha: "", localizacionGarrapatas: "" },
    { dia: "Día 28", pulgas: "", garrapatas: "", fecha: "", localizacionGarrapatas: "" },
  ]);
  const [checkboxState, setCheckboxState] = useState({
    prueba: false,
    referencia: false,
  });

  const [savePefiac003] = useMutation(SAVE_PEFIAC003);
  const [openPreview, setOpenPreview] = useState(false);
  const [pdfDataUrl, setPdfDataUrl] = useState(null);
  const handleFieldChange = (field) => {
    setCheckboxState(prevState => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  
  useEffect(() => {
  if (id) {
    setRegistro((prevState) => ({
      ...prevState,
      idFrcForm: id,
    }));
  }
  }, [id]);  
  const navigate = useNavigate();
  const handleSave = () => {
    console.log("Registro ID:.............", registro.idFrcForm);
    const input = {
      idFrcForm:  registro.idFrcForm,
      codigo: registro.codigo,
      fechaVigencia: registro.fechaVigencia,
      fechaRevision: registro.proximaRevision ? registro.proximaRevision : null, 
      prueba: checkboxState.prueba ? "Sí" : "No",
      referencia: checkboxState.referencia ? "Sí" : "No",
      nombreAnimal: registro.identificacionNombre,
      fechaAnimal: registro.identificacionFecha,
      nroGarrapatasDia1: visitas[0].garrapatas ? parseInt(visitas[0].garrapatas) : null,
      localizacionGarrapatasDia1: visitas[0].localizacionGarrapatas,
      nroPulgasDia1: visitas[0].pulgas ? parseInt(visitas[0].pulgas) : null,
      fechaDia2: visitas[1].fecha ? visitas[1].fecha : null,
      nroPulgasDia2: visitas[1].pulgas ? parseInt(visitas[1].pulgas) : null,
      nroGarrapatasDia2: visitas[1].garrapatas ? parseInt(visitas[1].garrapatas) : null,
      fechaDia3: visitas[2].fecha ? visitas[2].fecha : null,
      //nroPulgasDia3: visitas[2].pulgas ? parseInt(visitas[1].pulgas) : null,
      nroGarrapatasDia3: visitas[2].garrapatas ? parseInt(visitas[2].garrapatas) : null,
      fechaDia4: visitas[3].fecha ? visitas[3].fecha : null,
      nroPulgasDia4: visitas[3].pulgas ? parseInt(visitas[3].pulgas) : null,
      nroGarrapatasDia4: visitas[3].garrapatas ? parseInt(visitas[3].garrapatas) : null,
      fechaDia5: visitas[4].fecha ? visitas[4].fecha : null,
      //nroPulgasDia5: visitas[4].pulgas ? parseInt(visitas[1].pulgas) : null,
      nroGarrapatasDia5: visitas[4].garrapatas ? parseInt(visitas[4].garrapatas) : null,
      fechaDia6: visitas[5].fecha ? visitas[5].fecha : null,
      nroPulgasDia6: visitas[5].pulgas ? parseInt(visitas[5].pulgas) : null,
      nroGarrapatasDia6: visitas[5].garrapatas ? parseInt(visitas[5].garrapatas) : null,
    };
    
    console.log("Input para la mutación:..............", input);
    savePefiac003({ variables: { input } });
  };
  
  const handleGeneratePDF = () => {
    const pdfDataUrl = PDFPlanillasVR({ registro, visitas, checkboxState });
    setPdfDataUrl(pdfDataUrl); 
    setOpenPreview(true); 
  };

  const handleClosePreview = () => {
    setOpenPreview(false); 
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox p={3}>
      <SoftBox display="flex" justifyContent="flex-end" mb={2}>
          <SoftButton
            onClick={() => navigate("/proyectos")} // Redirige a la página de proyectos
            variant="contained"
            color="primary"
          >
            Volver
          </SoftButton>
        </SoftBox>
        <SoftTypography variant="h6" fontWeight="bold" sx={{ fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.6rem' } }} mb={2}>Planilla de Registro de Visitas</SoftTypography>

        <SoftTypography variant="subtitle2" fontWeight="bold" sx={{ fontSize: '0.875rem' }} mb={1}>Datos Generales</SoftTypography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>Código</InputLabel>
            <SoftInput
              placeholder="P-EFI-AC-003-V0.1"
              value={registro.codigo || ''}
              onChange={(e) => setRegistro({ ...registro, codigo: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>Fecha de Vigencia</InputLabel>
            <SoftInput
              type="date"
              value={registro.fechaVigencia || ''}
              onChange={(e) => setRegistro({ ...registro, fechaVigencia: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>Próxima Revisión</InputLabel>
            <SoftInput
              type="date"
              value={registro.proximaRevision || ''}
              onChange={(e) => setRegistro({ ...registro, proximaRevision: e.target.value })}
            />
          </Grid>
        </Grid>

        <SoftBox mt={2} ml={2} display="flex" gap={2}>
          <FormControlLabel
            control={<Checkbox checked={checkboxState.prueba} onChange={() => handleFieldChange('prueba')} />}
            label="Prueba"
            sx={{ fontSize: '0.875rem' }}
          />
          <FormControlLabel
            control={<Checkbox checked={checkboxState.referencia} onChange={() => handleFieldChange('referencia')} />}
            label="Referencia"
            sx={{ fontSize: '0.875rem' }}
          />
        </SoftBox>

        {/* Identificación */}
        <SoftTypography variant="subtitle2" fontWeight="bold" sx={{ fontSize: '0.875rem' }} mt={4} mb={1}>Identificación</SoftTypography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>Número de Identificación</InputLabel>
            <SoftInput
              value={registro.identificacionNumero || ''}
              onChange={(e) => setRegistro({ ...registro, identificacionNumero: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>Nombre del Animal</InputLabel>
            <SoftInput
              value={registro.identificacionNombre || ''}
              onChange={(e) => setRegistro({ ...registro, identificacionNombre: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>Fecha de Identificación</InputLabel>
            <SoftInput
              type="date"
              value={registro.identificacionFecha || ''}
              onChange={(e) => setRegistro({ ...registro, identificacionFecha: e.target.value })}
            />
          </Grid>
        </Grid>

        {/* Visitas */}
        <SoftTypography variant="subtitle2" fontWeight="bold" sx={{ fontSize: '0.875rem' }} mt={4} mb={1}>Visitas</SoftTypography>
        <Grid container spacing={2}>
          {visitas.map((visita, index) => (
            <Grid item xs={12} key={index}>
              <Paper sx={{ padding: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={1}>
                    <InputLabel sx={{ fontSize: '0.875rem' }}>Día</InputLabel>
                    <SoftTypography>{visita.dia}</SoftTypography>
                  </Grid>
                  <Grid item xs={8} sm={1.5}>
                    <InputLabel sx={{ fontSize: '0.875rem' }}>Fecha</InputLabel>
                    <SoftInput
                      type="date"
                      value={visita.fecha || ''}
                      onChange={(e) => {
                        const newVisitas = [...visitas];
                        newVisitas[index].fecha = e.target.value;
                        setVisitas(newVisitas);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={1}>
                    <InputLabel sx={{ fontSize: '0.875rem' }}>Pulgas</InputLabel>
                    <SoftInput
                      type="number"
                      value={visita.pulgas || ''}
                      onChange={(e) => {
                        const newVisitas = [...visitas];
                        newVisitas[index].pulgas = e.target.value;
                        setVisitas(newVisitas);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={1}>
                    <InputLabel sx={{ fontSize: '0.875rem' }}>Garrapatas</InputLabel>
                    <SoftInput
                      type="number"
                      value={visita.garrapatas || ''}
                      onChange={(e) => {
                        const newVisitas = [...visitas];
                        newVisitas[index].garrapatas = e.target.value;
                        setVisitas(newVisitas);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={7.5}>
                    <InputLabel sx={{ fontSize: '0.875rem' }}>Localización Garrapatas</InputLabel>
                    <SoftInput
                      value={visita.localizacionGarrapatas || ''}
                      onChange={(e) => {
                        const newVisitas = [...visitas];
                        newVisitas[index].localizacionGarrapatas = e.target.value;
                        setVisitas(newVisitas);
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <SoftTypography variant="subtitle2" fontWeight="bold" sx={{ fontSize: '0.875rem' }} mt={4} mb={1}>Identificación</SoftTypography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>investigadorResponsable</InputLabel>
            <SoftInput
              value={registro.investigadorResponsable || ''}
              onChange={(e) => setRegistro({ ...registro, investigadorResponsable: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>Investigador Secundario</InputLabel>
            <SoftInput
              value={registro.investigadorSecundario || ''}
              onChange={(e) => setRegistro({ ...registro, investigadorSecundario: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>Monitor</InputLabel>
            <SoftInput
              value={registro.monitor || ''}
              onChange={(e) => setRegistro({ ...registro, monitor: e.target.value })}
            />
          </Grid>
        </Grid>

        <SoftBox mt={3} display="flex" gap={2}>
          <SoftButton variant="contained" onClick={handleSave}>Guardar</SoftButton>
          <SoftButton variant="contained" onClick={handleGeneratePDF}>Generar PDF</SoftButton>
        </SoftBox>
        {/* <Dialog open={openPreview} onClose={handleClosePreview}>
          <DialogTitle>Vista Previa del PDF</DialogTitle>
          <DialogContent>
            <iframe src={pdfDataUrl} width="100%" height="400px"></iframe>
          </DialogContent>
          <DialogActions>
            <SoftButton onClick={handleClosePreview} color="primary">
              Cerrar
            </SoftButton>
            <SoftButton onClick={() => window.open(pdfDataUrl)} color="secondary">
              Descargar PDF
            </SoftButton>
          </DialogActions>
        </Dialog> */}
      </SoftBox>
    </DashboardLayout>
  );
};

RegistroVisitasForm.propTypes = {
  idFrcForm: PropTypes.string.isRequired,
};

export default RegistroVisitasForm;
